import { useEffect, useState } from "react";
import { Table, Input } from "antd";
// import { bindActionCreators } from "redux";
// import { useSelector, useDispatch } from "react-redux";
// import { useMediaQuery } from "react-responsive";
// import { useNavigate } from "react-router-dom";
// import formatter from "../../utils/formatter";
// import { storeActionCreators } from "../../redux/stores";
import { SearchOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import Dashboard from "../seller-mgmt/Dashboard";

const { Search } = Input;

const FindOrders = () => {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();

  const [noOrderFound] = useState(false);

  const [
    val,
    // setVal
  ] = useState("");

  // const { rate, code, indicator } = useSelector((state) => state.currency[0]);
  // const { info } = useSelector((state) => state.user);
  // const { settlementInfo, details, transactions } = useSelector(
  //   (state) => state.store
  // );

  // const { fetchTransactionInfo } = bindActionCreators(
  //   storeActionCreators,
  //   dispatch
  // );

  // const {
  //   account_number,
  //   current_net,
  //   current_balance,
  //   last_90_days,
  //   deductions,
  //   pending_balance,
  //   payment_due,
  //   next_pay_date,
  // } = settlementInfo;

  // const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  const isTabletOrMobile = false;

  const columns = [
    {
      title: "Designer Name",
      dataIndex: "brandName",
      key: "brandName",
    },
    {
      title: "Order ID",
      dataIndex: "order_id",
      key: "transactionId",
    },
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
      key: "transactionId",
    },
    {
      title: "Transaction Date",
      dataIndex: "transaction_date",
      key: "transactionDate",
    },
    {
      title: "Type",
      dataIndex: "transaction_type",
      key: "transactionType",
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "transactionDescription",
    // },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "transactionAmount",
      // render: (amount) => (
      //   <p className="price">{formatter(amount, rate, code, indicator)}</p>
      // ),
    },
    {
      title: "Status",
      dataIndex: "transaction_status",
      key: "transactionStatus",
      width: 250,
    },
  ];

  const mobileColumns = [
    {
      title: "Designer Name",
      dataIndex: "brandName",
      key: "brandName",
    },
    {
      title: "Date",
      dataIndex: "transaction_date",
      key: "transactionDate",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "transactionAmount",
    },
    Table.EXPAND_COLUMN,
    // {
    //   key: "see_details",
    //   render: (text, record) => (
    //     <div style={{color: "#49BDEF", textDecoration:"underline"}}>
    //       See Details
    //     </div>
    //   ),
    // },
  ];

  // const getTransaction = (val) => {
  //   setVal(val);
  //   fetchTransactionInfo(info.token, val, 2022);
  // };

  // useEffect(() => {
  //   getTransaction(getPrevMonth());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {}, [val]);

  return (
    <Dashboard
      headerTitle="The Designer Management Portal"
      //headerSubtitle="Get inisights on all users using your app"
    >
      <div className="manage-payment-container">
        <div className="manage-listing-all-content">
          <div className="manage-payment-coverall-container">
            {/* {isTabletOrMobile && (
            <div className="hello-store-mobile">Hello {details.storeName}</div>
          )} */}
            {/* <div className="manage-payment-adspace"></div> */}
            <div className="manage-payment-body-content-header">
              <div className="manage-payment-body-content-header-container">
                <div className="manage-payment-body-content-header-title">
                  Find Designer Order
                </div>
                <div className="manage-payment-body-content-header-subtitle">
                  You can see access designer transactions here
                </div>
              </div>
            </div>
            <div className="manage-payment-body-container">
              <div className="manage-payment-body-content">
                <div style={{ width: "50%", height: "48px", margin: "auto" }}>
                  <Search
                    className="search"
                    allowClear
                    enterButton="Search"
                    // onSearch={onSearch}
                    placeholder="Enter Order ID Or Designer Name"
                    prefix={<SearchOutlined />}
                  />
                </div>

                <div
                  className="manage-payment-body-content-section"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}
                >
                  <div>
                    <div className="manage-payment-body-content-trade-section-title">
                      Search Result
                    </div>
                    <div className="manage-payment-body-content-section-subtitle">
                      <strong className="trade-summary-big-number">
                        {/* {formatter(current_balance, rate, code, indicator)} */}
                        28,890
                      </strong>
                      {` `} Records Found for Order ID 234267522
                    </div>
                  </div>
                </div>

                <div className="manage-payment-body-content-section">
                  <Table
                    className="manage-payment-transaction-table"
                    // dataSource={transactions?.data}
                    columns={!isTabletOrMobile ? columns : mobileColumns}
                    expandRowByClick={true}
                    rowKey={(record) => record.transaction_id}
                    expandable={
                      isTabletOrMobile
                        ? {
                            expandedRowRender: (record) => (
                              <div>
                                <div className="expanded-row-item-container">
                                  <div className="expanded-row-item-container-title">
                                    ID:
                                  </div>
                                  <div className="expanded-row-item-container-data">
                                    {record.transaction_id}
                                  </div>
                                </div>
                                <div className="expanded-row-item-container">
                                  <div className="expanded-row-item-container-title">
                                    Date:
                                  </div>
                                  <div className="expanded-row-item-container-data">
                                    {record.transaction_date}
                                  </div>
                                </div>
                                <div className="expanded-row-item-container">
                                  <div className="expanded-row-item-container-title">
                                    Type:
                                  </div>
                                  <div className="expanded-row-item-container-data">
                                    {record.transaction_type}
                                  </div>
                                </div>
                                {/* <div className="expanded-row-item-container">
                          <div className="expanded-row-item-container-title" >Description:</div>
                          <div className="expanded-row-item-container-data">{record.transactionDescription}</div> 
                        </div> */}
                                <div className="expanded-row-item-container">
                                  <div className="expanded-row-item-container-title">
                                    Amount:
                                  </div>
                                  <div className="expanded-row-item-container-data">
                                    {record.amount}
                                  </div>
                                </div>
                                <div className="expanded-row-item-container">
                                  <div className="expanded-row-item-container-title">
                                    Status:
                                  </div>
                                  <div className="expanded-row-item-container-data">
                                    {record.transaction_status}
                                  </div>
                                </div>
                              </div>
                            ),
                            rowExpandable: (record) =>
                              record.transaction_id !== "Not Expandable",
                            expandIcon: ({ expanded, onExpand, record }) =>
                              expanded ? (
                                <div
                                  style={{
                                    color: "#49BDEF",
                                    textDecoration: "underline",
                                  }}
                                  onClick={(e) => onExpand(record, e)}
                                >
                                  Close Details
                                </div>
                              ) : (
                                <div
                                  style={{
                                    color: "#49BDEF",
                                    textDecoration: "underline",
                                  }}
                                  onClick={(e) => onExpand(record, e)}
                                >
                                  See Details
                                </div>
                              ),
                          }
                        : ""
                    }
                  />
                </div>

                {noOrderFound && (
                  <div className="no-order-found">
                    <div style={{ fontSize: "32px" }}>Search Result</div>
                    <ExclamationCircleFilled style={{ fontSize: "70px" }} />
                    <div>
                      No Records found. <br />
                      Review Order Id, or designer name and try again
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <style jsx="true">{`
          .manage-payment-container {
            font-family: DomaineSansText-Light;
            color: black;
            margin-top: 16px;
          }
          .manage-payment-coverall-container {
          }
          .manage-listing-all-content {
            position: relative;
          }

          .manage-payment-adspace {
            background-image: url("images/seller-stats-banner.png");
            height: 211px;
            background-repeat: no-repeat;
            width: 80%;
            background-position: bottom;
            background-color: black;
            margin: auto;
          }

          .manage-payment-body-container {
            display: flex;
            justify-content: space-between;
          }
          .manage-payment-body-content-header-container {
            margin-bottom: 24px;
          }
          .manage-payment-body-content-header {
            display: flex;
            justify-content: space-between;
          }
          .manage-payment-body-content-header-title {
            font-size: 24px;
            font-weight: bold;
          }
          .manage-payment-body-content-header-subtitle {
            font-size: 16px;
            color: #707070;
          }
          .manage-payment-body-sidebar-add-listing-button {
            height: 47px;
            background: white;
            color: black;
            font-size: 16px;
            display: flex;
            align-items: center;
            gap: 8px;
          }

          .manage-payment-body-content-listings-container {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 70px;
          }

          .manage-payment-body-content-top-actions {
            display: flex;
            gap: 40px;
            margin-bottom: 24px;
            align-items: center;
          }
          .top-actions-checkbox-container {
            padding: 5px;
            border: 1px solid #d4d4d4;
            border-radius: 4px;
          }
          .pagination-nav-text {
            font-family: "DomaineSansText-Light";
          }
          .pagination-nav-text:hover {
            color: #800000;
          }
          .activate-delete-button {
            background: black;
            color: white;
          }
          .manage-payment-body-content-header-container {
            margin-bottom: 24px;
          }
          .manage-payment-body-content-header-title {
            font-size: 24px;
            font-weight: bold;
          }
          .manage-payment-body-content-header-subtitle {
            font-size: 16px;
            color: #707070;
          }

          .manage-payment-body-content {
            width: 100%;
          }
          .manage-payment-body-content-listings-container {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 70px;
            // gap: 21px;
          }

          .manage-payment-body-content-top-actions {
            display: flex;
            gap: 40px;
            margin-bottom: 24px;
          }
          .top-actions-checkbox-container {
            padding: 5px;
            border: 1px solid #d4d4d4;
            border-radius: 4px;
          }
          .pagination-nav-text {
            font-family: "DomaineSansText-Light";
          }
          .pagination-nav-text:hover {
            color: #800000;
          }
          .activate-delete-button {
            background: black;
            color: white;
          }

          .no-listings-to-show {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 500px;
            font-weight: bold;
            font-size: 18px;
          }
          .no-listings-to-show-link {
            text-decoration: underline;
          }
          .no-listings-to-show-link:hover {
            cursor: pointer;
            color: #800000;
          }
          .ant-select-item-option-selected:not(
              .ant-select-item-option-disabled
            ) {
            background: white;
          }

          .manage-payment-payment-summary-section {
            display: flex;
            justify-content: space-between;
            width: 88%;
          }
          .payment-summary-grey-box {
            background: #f9f9f9;
            padding: 24px;
          }
          .payment-summary-pink-box {
            background: rgba(128, 0, 0, 0.05);
            padding: 24px;
            width: 450px;
          }
          .payment-summary-big-number {
            font-size: 24px;
            font-weight: bold;
            font-family: "DomaineSansText-Black";
            margin-top: 8px;
          }
          .payment-summary-pink-box-button {
            background: black;
            color: white;
            height: 47px;
            font-size: 16px;
            margin-top: 4px;
          }
          .payment-summary-underline-link {
            margin-top: 10px;
            padding: 16px 0;
            border-top: 1px solid #d4d4d4;
            font-size: 12px;
            color: #6f6f6f;
            text-decoration: underline;
          }
          .manage-payment-body-content-section-title {
            font-size: 18px;
            margin-bottom: 16px;
            font-weight: bold;
          }

          .manage-payment-body-content-trade-section-title {
            font-size: 18px;
            font-weight: bold;
          }

          .trade-summary-big-number {
            font-weight: bold;
            font-family: "DomaineSansText-Black";
          }
          .manage-payment-body-content-section-subtitle {
            font-size: 16px;
          }
          .trade-summary-date-select > .ant-select-selector {
            background-color: black !important;
            color: white;
            height: 47px !important;
            display: flex !important;
            align-items: center !important;
            width: 200px !important;
          }
          .trade-summary-date-select > .ant-select-arrow {
            color: white;
          }
          .manage-payment-body-content-section {
            margin-top: 40px;
          }
          .recent-transaction-image {
            background-repeat: no-repeat;
            width: 80px;
            height: 80px;
            background-size: cover;
          }
          .ant-input-search
            > .ant-input-group
            > .ant-input-group-addon:last-child
            .ant-input-search-button {
            background: #000;
            border: 1px solid #000;
            color: #fff;
            height: 48px;
            font-family: "DomaineSansText-Light";
            padding: 0 24px;
          }
          .ant-input-search
            .ant-input-group
            .ant-input-affix-wrapper:not(:last-child) {
            height: 48px;
          }
          .no-order-found {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 100px;
            font-size: 16px;
            gap: 24px;
            font-family: "DomaineSansText-Regular";
            text-align: center;
            margin: auto;
          }

          @media (max-width: 640px) {
            .manage-payment-coverall-container {
              margin-left: 0;
            }
            .manage-payment-adspace {
              background-image: url(images/seller-stats-banner.png);
              height: 120px;
              background-repeat: no-repeat;
              width: 100%;
              background-position: center;
              background-color: black;
              margin: auto;
              background-size: cover;
            }
            .hello-store-mobile {
              padding: 0px 16px 16px;
              font-weight: bold;
              font-size: 18px;
            }

            .manage-payment-body-main {
              padding: 0 16px;
              padding-bottom: 40px;
              margin-right: 0;
            }
            .manage-payment-body-content-header-container {
              display: flex;
              align-items: center;
            }
            .manage-payment-body-content-header-title {
              font-size: 18px;
            }
            .manage-payment-body-sidebar-add-listing-button {
              padding: 0 16px;
              height: 40px;
              font-size: 14px;
              width: 180px;
            }

            .manage-payment-body-content-listings-container {
              display: block;
              margin-bottom: 40px;
            }
            .manage-payment-body-content-header-container {
              display: block;
              align-items: center;
              margin-bottom: 16px;
            }
            .manage-payment-body-content-header {
              padding: 24px 16px;
              flex-direction: column;
            }
            .manage-payment-body-content-header-subtitle {
              width: 100%;
              font-size: 14px;
            }
            .manage-payment-body-container {
              flex-direction: column-reverse;
              margin-top: 0;
              justify-content: center;
              margin: 0;
            }
            .manage-payment-body-sidebar {
              width: 100%;
              margin-top: 0;
              margin-bottom: 24px;
              padding: 0 16px;
              border: 0;
              margin-right: 0;
              display: flex;
              justify-content: flex-end;
            }
            .manage-payment-body-sidebar-content {
              margin: 0px;
              display: flex;
              align-items: center;
              gap: 8px;
            }
            .manage-payment-body-sidebar-content-title {
              font-weight: normal;
              font-size: 12px;
              margin-bottom: 0px;
            }
            .manage-payment-body-sidebar-content-select {
              width: auto;
            }
            .manage-payment-body-sidebar-content-select .ant-select-selector {
              margin: 0 !important;
              height: auto !important;
              font-size: 12px !important;
              padding: 0 16px !important;
            }
            .manage-payment-body-sidebar-content-select .ant-select-arrow {
              top: 50%;
              height: auto;
              width: auto;
            }
            .manage-payment-body-content {
              width: 100%;
              padding: 0 16px;
            }
            .manage-payment-body-content-top-actions {
              gap: 16px;
              margin-top: 16px;
              display: none;
            }
            .manage-payment-payment-summary-section {
              width: 100%;
              flex-direction: column;
              gap: 16px;
            }
            .payment-summary-grey-box {
              padding: 16px;
              font-size: 12px;
            }
            .payment-summary-pink-box {
              padding: 16px;
              width: auto;
              font-size: 12px;
            }
            .payment-summary-big-number {
              font-size: 14px;
            }
            .payment-summary-pink-box-button {
              font-size: 12px;
              padding: 16px;
              line-height: 1;
            }
            .manage-payment-body-content-section {
              margin-top: 24px;
            }
            .manage-payment-body-content-section-subtitle {
              font-size: 14px;
            }
            .payment-summary-underline-link {
              padding-bottom: 0px;
            }
            .manage-payment-transaction-table .ant-table {
              font-size: 12px;
            }
            .expanded-row-item-container {
              display: flex;
              gap: 8px;
              margin-bottom: 8px;
            }
            .expanded-row-item-container-title {
              min-width: 75px;
            }
            .expanded-row-item-container-data {
              font-weight: bold;
            }
          }
        `}</style>
      </div>
    </Dashboard>
  );
};

export default FindOrders;

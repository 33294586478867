import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { brandActionCreators } from "../../redux/seller";
import { Avatar, Button, Tag, Popconfirm, message } from "antd";
import Dashboard from "./Dashboard";
import { isEmptyObject } from "../../utils/isEmptyObject";

const Product = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedProduct = location.state;

  const {
    fetchProduct,
    fetchCategories,
    deleteProduct,
    activateProduct,
    deactivateProduct,
  } = bindActionCreators(brandActionCreators, dispatch);
  const { info } = useSelector((state) => state.user);
  const { brand, product } = useSelector((state) => state.brands);

  const images = [product.img1, product.img2, product.img3, product.img4];

  const confirm = async () => {
    const res = await deleteProduct(
      info.token,
      selectedProduct.id,
      brand.seller_id
    );
    if (res.status === "ok") {
      message.success(res.message);
      history.goBack();
    }
    if (res.status === "not ok") message.error(res.message);
  };

  const activate = async (id) => {
    const res = await activateProduct(info.token, brand.brand_id, id);
    if (res.status === "ok") {
      message.success(res.message);
    }
    if (res.status === "not ok") message.error(res.message);
  };
  const deactivate = async (id) => {
    const res = await deactivateProduct(info.token, brand.brand_id, id);
    if (res.status === "ok") {
      message.success(res.message);
    }
    if (res.status === "not ok") message.error(res.message);
  };

  useEffect(() => {
    fetchProduct(info.token, brand.brand_id, selectedProduct.id);
    fetchCategories(info.token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product?.status]);
  return (
    <Dashboard>
      <div className="div-back" onClick={() => history.goBack()}>
        <img src="/images/arrow-back.svg" alt="back" />
        <span className="txt-back">Back to Designer's Information</span>
      </div>
      <div className="product-container">
        <div className="details">
          <div className="box">
            <Avatar src={product.img1} size={100} />
            <div className="info">
              <h3 className="name">{product.name}</h3>
              <span className="id">Product ID: {product.product_id}</span>
              <h5 className="quantity">Quantity</h5>
              <span className="value">
                {product.quantity} {``}Pcs
              </span>
            </div>
          </div>
          <div className="box">
            <span className="line" />
            <div className="info">
              <h5 className="txt-amount">Amount</h5>
              <h3 className="txt-val">{product.price}</h3>
              <h5 className="quantity">Description</h5>
              <p className="desc">{product.description}</p>
            </div>
          </div>
          <div className="box">
            {!isEmptyObject(product) && (
              <div className="status-div">
                {product.status ? (
                  <Button
                    className="btn-deactivate"
                    onClick={() => deactivate(product.product_id)}
                  >
                    Deactivate Product
                  </Button>
                ) : (
                  <Button
                    className="btn-activate"
                    onClick={() => activate(product.product_id)}
                  >
                    Activate Product
                  </Button>
                )}
                <Tag
                  color={product.status ? "success" : "warning"}
                  style={{
                    borderRadius: "100px",
                    marginTop: "24px",
                    width: "80px",
                  }}
                >
                  {product.status ? "Approved" : "Pending"}
                </Tag>
              </div>
            )}
          </div>
        </div>
        <div className="img-box">
          <h2 className="txt-title">Product Images</h2>
          <div className="img-container">
            {images.map((img) => (
              <div className="img" style={{ backgroundImage: `url(${img})` }} />
            ))}
          </div>
        </div>
        <div className="btn-container">
          <Popconfirm
            title="Are you sure to delete this product?"
            onConfirm={confirm}
            okText="Yes"
            cancelText="No"
          >
            <Button className="btn-del" type="primary">
              Delete Product
            </Button>
          </Popconfirm>

          <Button
            className="btn-edit"
            type="ghost"
            onClick={() =>
              history.push(
                `/${brand.slug}/${product.slug}/edit`,
                product.product_id
              )
            }
          >
            Edit Product
          </Button>
        </div>
      </div>
      <style jsx="true">{`
        .div-back {
          cursor: pointer;
        }
        .txt-back {
          font: normal normal 16px/19px DomaineSansText-Regular;
          color: #545f7d;
          margin-left: 14.41px;
        }
        .details {
          align-items: flex-start;
          background: #ffffff;
          border: 1px solid rgba(1, 3, 10, 0.06);
          box-sizing: border-box;
          box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.04);
          border-radius: 4px;
          display: flex;
          justify-content: space-between;
          margin-top: 82px;
          padding: 30px;
        }
        .box {
          display: flex;
          justify-content: space-between;
        }
        .status-div {
          display: flex;
          flex-direction: column;
        }
        .btn-deactivate {
          background: #be002b;
          border: 1px solid #dadada;
          border-radius: 4px;
          box-sizing: border-box;
          color: #fff;
          font: normal normal 12px/16px DomaineSansText-Regular;
          height: 40px;
        }
        .btn-deactivate:hover {
          background: #be002b;
          border: 1px solid #dadada;
          color: #dadada;
          opacity: 0.8;
        }
        .btn-activate {
          background: #000;
          border: 1px solid #dadada;
          border-radius: 4px;
          box-sizing: border-box;
          color: #dadada;
          font: normal normal 12px/16px DomaineSansText-Regular;
          height: 40px;
          width: 170px;
        }
        .btn-activate:hover {
          background: #000;
          border: 1px solid #dadada;
          color: #dadada;
          opacity: 0.8;
        }
        .info {
          margin-top: 24px;
          margin-left: 33px;
        }
        .name {
          font: normal 500 22px/26px DomaineSansText-Regular;
          margin-bottom: 0px;
        }
        .id {
          font: normal normal 14px/16px DomaineSansText-Regular;
          color: #545f7d;
          margin-top: 6px;
          margin-bottom: 0px;
        }
        .quantity {
          font: normal normal 14px/16px DomaineSansText-Regular;
          color: #545f7d;
          margin-top: 26px;
        }
        .value {
          font: normal 500 16px/19px DomaineSansText-Regular;
          color: #545f7d;
          margin-top: 8px;
        }
        .line {
          opacity: 0.2;
          border: 1px solid #545f7d;
          height: 80px;
        }
        .txt-amount {
          font: normal 500 14px/16px DomaineSansText-Regular;
          color: #545f7d;
        }
        .txt-val {
          font: normal 700 16px/19px DomaineSansText-Regular;
          color: #000000;
        }
        .desc {
          font: normal normal 11px/13px DomaineSansText-Regular;
          color: #212f4b;
          width: 235px;
        }
        .img-box {
          align-items: flex-start;
          background: #ffffff;
          border: 1px solid rgba(1, 3, 10, 0.06);
          box-sizing: border-box;
          box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.04);
          border-radius: 4px;
          margin-top: 82px;
          padding: 30px;
        }
        .txt-title {
          font: normal 500 16px/19px DomaineSansText-Regular;
          color: #000000;
        }
        .img-container {
          display: flex;
          justify-content: space-between;
          margin-top: 67px;
          margin-bottom: 67px;
        }
        .img {
          background-repeat: no-repeat;
          background-size: contain;
          height: 295px;
          width: 214px;
        }
        .btn-container {
          display: flex;
          justify-content: space-between;
          margin: 30px 0 157px auto;
          width: 410px;
        }
        .btn-del {
          font: normal 500 18px/21px DomaineSansText-Regular;
          height: 53px;
          width: 195px;
        }
        .btn-edit {
          background: #000;
          color: #fff;
          font: normal 500 18px/21px DomaineSansText-Regular;
          height: 53px;
          width: 195px;
        }
      `}</style>
    </Dashboard>
  );
};

export default Product;

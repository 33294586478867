import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Dashboard from "../seller-mgmt/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import { getShootDetails } from "../../redux/seller/seller.actions";

const ScheduledPhotoshootInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { info } = useSelector((state) => state.user);
  const { photoshootDetails } = useSelector((state) => state.brands);
  const { id } = useParams();

  const {
    first_name,
    last_name,
    email,
    phone_dial,
    phone_number,
    photoshoot_plan,
    brand_name,
    photoshoot_date,
  } = photoshootDetails || {};

  useEffect(() => {
    dispatch(getShootDetails(info.token, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dashboard
      headerTitle="The Designer Management Portal"
      //headerSubtitle="Get inisights on all users using your app"
      dashboardBgColor="#f7f7f7"
    >
      <div className="manage-payment-container">
        <div className="manage-listing-all-content">
          <div className="manage-payment-body-container">
            <div className="manage-payment-body-content">
              <div style={{ padding: "36px 0px 8px" }}>
                <div
                  style={{
                    marginBottom: "16px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => history.goBack()}
                >
                  <svg
                    width="28"
                    height="10"
                    viewBox="0 0 28 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.94997 5.35647C0.994502 5.47131 1.0613 5.57678 1.14684 5.66583L4.89684 9.41583C5.07263 9.59278 5.31285 9.69356 5.56248 9.69356C5.81211 9.69356 6.05232 9.59278 6.22812 9.41583C6.40508 9.24004 6.50586 8.99982 6.50586 8.75019C6.50586 8.50056 6.40508 8.26035 6.22812 8.08455L4.07187 5.93769H26.6562C27.1742 5.93769 27.5937 5.51817 27.5937 5.00019C27.5937 4.48221 27.1742 4.06269 26.6562 4.06269H4.07187L6.22812 1.91583C6.5961 1.54785 6.5961 0.952559 6.22812 0.584579C5.86014 0.216599 5.26485 0.216599 4.89687 0.584579L1.14687 4.33458C1.06133 4.42364 0.994532 4.52911 0.95 4.64394C0.901952 4.75644 0.876173 4.87714 0.875 5.00019C0.876172 5.12324 0.901953 5.24394 0.95 5.35644L0.94997 5.35647Z"
                      fill="black"
                    />
                  </svg>

                  <span
                    style={{
                      fontFamily: "DomaineSansText-Light",
                      marginLeft: "8px",
                    }}
                  >
                    Back
                  </span>
                </div>
              </div>
              <div
                className="manage-payment-body-content-section"
                style={{ position: "relative" }}
              >
                <div
                  style={{
                    fontSize: "24px",
                    fontFamily: "DomaineSansText-Light",
                    fontWeight:'bold',
                    textTransform: "capitalize",
                    marginBottom: "16px",
                  }}
                >
                  Designer Information
                </div>
                <div style={{display:'flex', gap:"8px"}}>
                  <div className="preview-main-content">
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">
                        Designer
                      </div>
                      <div className="preview-main-content-row-data">
                        {brand_name}
                      </div>
                    </div>
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">
                        First Name
                      </div>
                      <div className="preview-main-content-row-data">
                        {first_name}
                      </div>
                    </div>
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">
                        Last Name
                      </div>
                      <div className="preview-main-content-row-data">
                        {last_name}
                      </div>
                    </div>
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">Email</div>
                      <div className="preview-main-content-row-data">{email}</div>
                    </div>
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">
                        Phone Number
                      </div>
                      <div className="preview-main-content-row-data">
                        {phone_dial}
                        {phone_number}
                      </div>
                    </div>
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">
                        Photoshoot Plan
                      </div>
                      <div className="preview-main-content-row-data">
                        {photoshoot_plan}
                      </div>
                    </div>
                  </div>
                  <div
                      style={{
                        borderLeft: "1px solid #0000007a",
                        marginRight:'24px'
                      }}
                    ></div>
                  <div style={{display:'flex', flexDirection:'column', justifyContent:'center', width:"47%"}}>
                    <div
                      className="preview-main-content-row-data"
                      style={{ marginBottom: "10px", fontSize: "16px", textAlign:'left' }}
                    >
                      Select Photoshoot Date
                    </div>
                   <div>{photoshoot_date}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <style jsx="true">{`
          .manage-payment-container {
            font-family: DomaineSansText-Light;
            color: black;
            margin-top: -32px;
          }
          .manage-listing-all-content {
            position: relative;
          }

          .manage-payment-body-container {
            margin-bottom: 40px;
          }
          .manage-payment-body-content {
            width: 100%;
          }
          .manage-payment-body-content-section {
            // margin-top: 24px;
            background: white;
            padding: 24px;
          }
          .preview-main-content {
            // margin: 16px 0 0;
            width:50%
          }
          .preview-main-content-row {
            padding: 11px 0;
            display: grid;
            grid-template-columns: 30% 1fr;
            grid-gap: 24px;
          }
          .preview-main-content-row-data {
            font-family: "DomaineSansText-Regular";
            width: 90%;
            text-align: right;
          }
          .preview-main-content-row-key {
            color: #6f6f6f;
          }
          .preview-button-black {
            height: 48px;
            color: white;
            background: #000;
            font-size: 16px;
            width: 30%;
          }
          .preview-button-white {
            height: 48px;
            background: #fff;
            color: #000;
            font-size: 16px;
            width: 30%;
          }
          .preview-button-white:hover {
            background: #000;
            color: #fff;
          }
          .confirmModal .ant-modal-body {
            background: black;
            color: white;
            font-family: "DomaineSansText-Light";
            text-align: center;
            padding: 60px 40px;
          }
          ..trade-summary-date-select > .ant-select-selector {
            height: 40px !important;
            display: flex !important;
            align-items: center !important;
            width: 120px !important;
          }
          .date {
            width: 100%;
            height: 50px;
          }
        `}</style>
      </div>
    </Dashboard>
  );
};

export default ScheduledPhotoshootInfo;

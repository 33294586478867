import { useLayoutEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import formatter from "../../utils/formatter";
import { brandActionCreators } from "../../redux/seller";
import { Button, Input, Table, Tooltip, message, Modal, Radio } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import Dashboard from "../seller-mgmt/Dashboard";
import colors from "./assets/colors";
import PreviewLisitingModal from "./PreviewLisitingModal";

// const { Search } = Input;

const ListingPreview = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isError, setShowError] = useState(false);
  const [previewModalVisible, setPreviewModalVisible] = useState(false);
  const [rejectListingModalVisible, setRejectListingModalVisible] =
    useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [rejectSuccessModalVisible, setRejectSuccessModalVisible] =
    useState(false);
  const [approvedSuccessModalVisible, setApprovedSuccessModalVisible] =
    useState(false);

  const { info } = useSelector((state) => state.user);
  const { product } = useSelector((state) => state.brands);

  const { fetchProduct, approveProduct, deactivateProduct, rejectProduct } =
    bindActionCreators(brandActionCreators, dispatch);

  const productId = window.location.pathname.split("/")[2];

  // const prodArr = [];
  // product?.variety?.map((item) => {
  //   if (prodArr.length > 0) {
  //     let value = prodArr.find((v) => v.color === item.color);
  //     if (value) {
  //       value.details.push({ size: item.size, qty: item.quantity });
  //     } else {
  //       prodArr.push({
  //         color: item.color,
  //         hex: item?.color_object?.hex,
  //         details: [{ size: item.size, qty: item.quantity }],
  //       });
  //     }
  //   } else {
  //     prodArr.push({
  //       color: item.color,
  //       hex: item?.color_object?.hex,
  //       details: [{ size: item.size, qty: item.quantity }],
  //     });
  //   }
  //   return true;
  // });

  if (rejectReason !== "" && isError) setShowError(false);

  const activateProduct = async () => {
    const res = await approveProduct(info.token, product.product_id);
    if (res.status === "ok") {
      message.success(res.message);
      setApprovedSuccessModalVisible(true);
    }
    if (res.status === "not ok") message.error(res.message);
  };
  const onRejectProduct = async () => {
    if (rejectReason === "") return setShowError(true);
    const res = await rejectProduct(
      info.token,
      product.product_id,
      rejectReason
    );
    if (res.status === "ok") {
      setRejectListingModalVisible(false);
      setRejectSuccessModalVisible(true);
    }
    if (res.status === "not ok") message.error(res.message);
  };

  const onDeactivateProduct = async () => {
    const res = await deactivateProduct(info.token, product.product_id);
    if (res.status === "ok") message.success(res.message);
    if (res.status === "not ok") message.error(res.message);
  };

  // const Status = () => {
  //   const status = product?.status?.toLowerCase();
  //   let color = "success";

  //   if (status === "pending approval") color = "warning";
  //   if (status === "rejected") color = "error";
  //   if (status === "deactivated") color = "magenta";
  //   return (
  //     <Tag color={color} className="txt-product-status store-preview-status">
  //       {status}
  //     </Tag>
  //   );
  // };

  const standardize_color = (str) => {
    let color = colors.find((color) => color.name === str);
    const ctx = document.createElement("canvas").getContext("2d");
    ctx.fillStyle = color?.hex;
    return ctx.fillStyle;
  };

  const editItem = async () => {
    const res = await fetchProduct(info.token, productId);
    if (res.status === "ok") {
      history.push("/edit-listing", { state: "listing" });
    }
  };

  useLayoutEffect(() => {
    fetchProduct(info.token, productId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const columns = [
    {
      title: "Item name",
      dataIndex: "itemName",
      key: "name",
      width: 300,
      render: (text, record) => <p>{product?.name}</p>,
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      render: (text, record) => (
        <div
          className="size-letter"
          style={{ color: "white", backgroundColor: "black", padding: "10px" }}
        >
          {record.size}
        </div>
      ),
      width: 200,
    },
    {
      align: "center",
      title: "Color",
      dataIndex: "color",
      key: "color",
      render: (text, record) => (
        <div
          className="color-container"
          style={{ width: "110px", height: "45px" }}
        >
          <div
            style={{ background: standardize_color(record?.color) }}
            className="color-hex-box"
          ></div>
          <div className="color-name">{record?.color}</div>
        </div>
      ),
      width: 250,
    },
    {
      title: (
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <div>Quantity</div>
          <Tooltip title="Ensure online quantity matches offline availability at all times. If you sell an item offline please update item quantity ">
            <InfoCircleOutlined
              style={{
                color: "rgba(0,0,0,.45)",
                fontSize: "16px",
                marginRight: "20px",
              }}
            />
          </Tooltip>
        </div>
      ),
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => (
        <div className="table-quantity-container">
          <Input
            disabled
            value={record.quantity}
            className="input"
            type="number"
            style={{ height: "45px" }}
          />
        </div>
      ),
    },
  ];

  function capitalizeStr(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1).toLowerCase();
  }

  return (
    <Dashboard
      headerTitle="The Designer Management Portal"
      //headerSubtitle="Get inisights on all users using your app"
      dashboardBgColor="#f7f7f7"
    >
      <div className="manage-payment-container">
        <div className="manage-listing-all-content">
          <div className="manage-payment-coverall-container">
            <div className="manage-payment-body-content-header">
              {/* <div
                style={{
                  width: "50%",
                  height: "48px",
                  margin: "16px auto 24px",
                }}
              >
                <Search
                  className="search"
                  allowClear
                  enterButton="Search"
                  // onSearch={onSearch}
                  placeholder="Enter Seller Name"
                  prefix={<SearchOutlined />}
                />
              </div> */}
              <div className="manage-payment-body-content-header-container">
                <div
                  className="manage-payment-body-content-header-title"
                  style={{ marginTop: "16px" }}
                >
                  Listings Preview
                </div>
                {/* <div className="manage-payment-body-content-header-subtitle">
                  You can see access seller transactions here
                </div> */}
              </div>
            </div>
            <div className="manage-payment-body-container">
              <div className="manage-payment-body-content">
                <div
                  className="manage-payment-body-content-section"
                  style={{ position: "relative" }}
                >
                  {/* <Status /> */}

                  <div className="product-main-container">
                    <div className="images-section">
                      <div className="image-large"></div>
                      <div className="images-thumbnail-container">
                        <div
                          style={{ backgroundImage: `url(${product?.image2})` }}
                        ></div>
                        <div
                          style={{ backgroundImage: `url(${product?.image3})` }}
                        ></div>
                        <div
                          style={{ backgroundImage: `url(${product?.image4})` }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="manage-payment-body-container">
              <div className="manage-payment-body-content">
                <div
                  className="manage-payment-body-content-section"
                  style={{ position: "relative" }}
                >
                  <div className="preview-main-content-header">
                    <div
                      style={{
                        fontSize: "18px",
                        fontFamily: "DomaineSansText-Regular",
                        textTransform: "capitalize",
                      }}
                    >
                      {product?.brand_name}
                    </div>
                    <div style={{ cursor: "pointer" }}>
                      <svg
                        width="13"
                        height="13"
                        viewBox="0 0 14 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.332 1.50004C10.5071 1.32494 10.715 1.18605 10.9438 1.09129C11.1725 0.996527 11.4177 0.947754 11.6654 0.947754C11.913 0.947754 12.1582 0.996527 12.387 1.09129C12.6157 1.18605 12.8236 1.32494 12.9987 1.50004C13.1738 1.67513 13.3127 1.883 13.4074 2.11178C13.5022 2.34055 13.551 2.58575 13.551 2.83337C13.551 3.08099 13.5022 3.32619 13.4074 3.55497C13.3127 3.78374 13.1738 3.99161 12.9987 4.16671L3.9987 13.1667L0.332031 14.1667L1.33203 10.5L10.332 1.50004Z"
                          stroke="black"
                          stroke-width="0.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <span style={{ marginLeft: "4px" }} onClick={editItem}>
                        Edit
                      </span>
                    </div>
                  </div>
                  <div className="preview-main-content">
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">
                        Item title
                      </div>
                      <div className="preview-main-content-row-data">
                        {product?.name}
                      </div>
                    </div>
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">
                        Ready to wear or Bespoke
                      </div>
                      <div className="preview-main-content-row-data txt-type">
                        {product?.product_type}
                      </div>
                    </div>
                    {product?.product_type === "bespoke" && (
                      <div className="preview-main-content-row">
                        <div className="preview-main-content-row-key">
                          Number of Days
                        </div>
                        <div className="preview-main-content-row-data">
                          {product?.bespoke_duration} Days
                        </div>
                      </div>
                    )}
                    {product?.product_type !== "bespoke" && (
                      <div className="preview-main-content-row">
                        <div className="preview-main-content-row-key">
                          Return policy
                        </div>
                        <div className="preview-main-content-row-data">
                          {product?.product_return_type?.map((type) => (
                            <span>{type} </span>
                          ))}
                        </div>
                      </div>
                    )}
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">
                        Main Category
                      </div>
                      <div className="preview-main-content-row-data">
                        {product?.main_cat}
                      </div>
                    </div>
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">
                        Subcategory
                      </div>
                      <div className="preview-main-content-row-data">
                        {product?.sub_cat}
                      </div>
                    </div>
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">
                        Classification
                      </div>
                      <div className="preview-main-content-row-data">
                        {product?.category_name}
                      </div>
                    </div>
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">
                        Occasions
                      </div>
                      <div className="preview-main-content-row-data">
                        <ul style={{ paddingInlineStart: "16px" }}>
                          {product?.occassion_type?.map((type) => (
                            <li>{type}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">
                        Exclusive Group
                      </div>
                      <div className="preview-main-content-row-data">
                        {product?.group}
                      </div>
                    </div>
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">Fit</div>
                      <div className="preview-main-content-row-data">
                        {product?.size_and_fit}
                      </div>
                    </div>
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">
                        Item Description
                      </div>
                      <div className="preview-main-content-row-data">
                        {product?.description}
                      </div>
                    </div>
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">
                        Material and Care Information
                      </div>
                      <div className="preview-main-content-row-data">
                        {product?.material_info}
                      </div>
                    </div>
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">Price</div>
                      <div className="preview-main-content-row-data">
                        {formatter(product?.price)}
                      </div>
                    </div>
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">Color</div>
                      <div className="preview-main-content-row-data">
                        <div style={{ display: "flex", gap: "24px" }}>
                          {product?.colors?.map((color) => (
                            <div className="product-color-section">
                              <div
                                className="product-color-section-box"
                                style={{
                                  background: `${standardize_color(color)}`,
                                }}
                              ></div>
                              <div>{color}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">Size</div>
                      <div className="preview-main-content-row-data">
                        <div style={{ display: "flex", gap: "24px" }}>
                          <ul style={{ paddingInlineStart: "16px" }}>
                            {product?.sizes?.map((size) => (
                              <li>{size}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {product?.variety && product?.variety?.length > 0 && (
                      <div className="preview-main-content-row">
                        <div className="preview-main-content-row-key"> </div>
                        <div className="preview-main-content-row-data">
                          <Table
                            dataSource={product?.variety}
                            columns={columns}
                            pagination={false}
                          />
                        </div>
                      </div>
                    )}
                    {product?.has_discount && (
                      <>
                        <div className="preview-main-content-row">
                          <div className="preview-main-content-row-key">
                            Discount
                          </div>
                          <div className="preview-main-content-row-data">
                            {product?.discounts_percent}%
                          </div>
                        </div>
                        <div className="preview-main-content-row">
                          <div className="preview-main-content-row-key">
                            Discount Date
                          </div>
                          <div className="preview-main-content-row-data">
                            {product?.discounts_start_date.split(" ")[0]} -
                            {product?.discounts_end_date.split(" ")[0]}
                          </div>
                        </div>
                      </>
                    )}

                    {product?.delivery_type && (
                      <div className="preview-main-content-row">
                        <div className="preview-main-content-row-key">
                          Delivery
                        </div>
                        <div className="preview-main-content-row-data">
                          {product?.delivery_type &&
                            capitalizeStr(
                              product?.delivery_type === "losode"
                                ? "We work with third-party delivery providers to handle your delivery"
                                : product?.delivery_type
                            )}
                        </div>
                      </div>
                    )}
                    <div className="preview-main-content-row">
                      <div className="preview-main-content-row-key">Weight</div>
                      <div className="preview-main-content-row-data">
                        {product?.product_weight} kg
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "40px",
              }}
            >
              {product?.status === "Deactivated" && (
                <Button
                  className="preview-button-black"
                  onClick={activateProduct}
                >
                  Approve
                </Button>
              )}
              {product?.status === "Pending Approval" && (
                <>
                  <Button
                    className="preview-button-black"
                    onClick={activateProduct}
                  >
                    Approve
                  </Button>
                  <Button
                    className="preview-button-white"
                    onClick={() => setRejectListingModalVisible(true)}
                  >
                    Reject
                  </Button>
                </>
              )}
              {product?.status === "Activated" && (
                <>
                  <Button
                    className="preview-button-black"
                    onClick={onDeactivateProduct}
                  >
                    Deactivate
                  </Button>
                  <Button className="preview-button-white" onClick={editItem}>
                    Edit
                  </Button>
                </>
              )}

              <Button
                className="preview-button-black"
                onClick={() => setPreviewModalVisible(true)}
              >
                Preview
              </Button>
            </div>
          </div>
        </div>

        <PreviewLisitingModal
          product={product}
          modalVisible={previewModalVisible}
          setModalVisible={setPreviewModalVisible}
        />

        <Modal
          centered
          visible={rejectListingModalVisible}
          onCancel={() => setRejectListingModalVisible(false)}
          footer={null}
          className="rejectListingModal"
          width={"60%"}
          // closeIcon={
          // <CloseCircleFilled
          // style={{ color: "#000", padding: "40px" }}
          maskClosable={false}
        >
          <div style={{ padding: "0 20px", fontSize: "14px" }}>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "24px",
                marginBottom: "4px",
              }}
            >
              Why are you rejecting this listing?
            </div>
            <div
              style={{
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Select your reason below
            </div>
            <div>
              <Radio.Group
                onChange={(e) => setRejectReason(e.target.value)}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "16px",
                  margin: "30px auto 16px",
                }}
              >
                <Radio value="unclear-image">Unclear image(s)</Radio>
                <Radio value="incomplete-info">Incomplete information</Radio>
                <Radio value="invalid-item">
                  Item cannot be sold on Losode
                </Radio>
                <Radio value="price-incorrect">
                  Item price appears to be incorrect
                </Radio>
                <Radio value="reason not here">My reason is not here</Radio>
              </Radio.Group>
              {rejectReason === "reason not here" && (
                <Input.TextArea
                  style={{ minHeight: "100px", marginTop: "16px" }}
                  className="no-reason-textbox"
                  placeholder="Enter reason here"
                />
              )}
              {isError && (
                <p style={{ color: "red" }}>Please Select a reason</p>
              )}
            </div>

            <div style={{ display: "flex", gap: "40px", marginTop: "40px" }}>
              <Button
                className="reject-btn-save"
                onClick={() => {
                  onRejectProduct();
                }}
              >
                Reject
              </Button>

              <Button
                className="reject-btn-cancel"
                onClick={() => {
                  setRejectListingModalVisible(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          centered
          visible={rejectSuccessModalVisible}
          onCancel={() => setRejectSuccessModalVisible(false)}
          footer={null}
          className="rejectListingSuccessModal"
          width={"60%"}
          // closeIcon={
          // <CloseCircleFilled
          // style={{ color: "#000", padding: "40px" }}
          maskClosable={false}
        >
          <div style={{ textAlign: "center" }}>
            An email has been sent to the designer notifying them that their
            listing has been rejected
          </div>
        </Modal>

        <Modal
          centered
          visible={approvedSuccessModalVisible}
          onCancel={() => setApprovedSuccessModalVisible(false)}
          footer={null}
          className="rejectListingSuccessModal"
          width={"60%"}
          // closeIcon={
          // <CloseCircleFilled
          // style={{ color: "#000", padding: "40px" }}
          maskClosable={false}
        >
          <div style={{ textAlign: "center" }}>
            You have now approved this listing. <br />
            An email has been sent to the designer notifying them that their
            listing has been approved
          </div>
        </Modal>

        <style jsx="true">{`
          .manage-payment-container {
            font-family: DomaineSansText-Light;
            color: black;
            margin-top: -32px;
          }
          .manage-payment-coverall-container {
          }
          .manage-listing-all-content {
            position: relative;
          }

          .manage-payment-adspace {
            height: 211px;
            background-repeat: no-repeat;
            width: 80%;
            background-position: bottom;
            background-color: black;
            margin: auto;
          }

          .manage-payment-body-container {
            margin-bottom: 40px;
          }
          .manage-payment-body-content-header-container {
          }
          .manage-payment-body-content-header {
            background: white;
            padding: 24px;
          }
          .manage-payment-body-content-header-title {
            font-size: 24px;
            font-family: "DomaineSansText-Regular";
          }
          .manage-payment-body-content-header-subtitle {
            font-size: 16px;
            color: #707070;
          }
          .manage-payment-body-sidebar-add-listing-button {
            height: 47px;
            background: white;
            color: black;
            font-size: 16px;
            display: flex;
            align-items: center;
            gap: 8px;
          }
          .confirm-listing-button {
            height: 48px;
            background: black;
            color: white;
            font-size: 16px;
            width: 180px;
            margin-left: 20px;
          }

          .manage-payment-body-content-listings-container {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 70px;
          }

          .manage-payment-body-content-top-actions {
            display: flex;
            gap: 40px;
            margin-bottom: 24px;
            align-items: center;
          }
          .top-actions-checkbox-container {
            padding: 5px;
            border: 1px solid #d4d4d4;
            border-radius: 4px;
          }
          .pagination-nav-text {
            font-family: "DomaineSansText-Light";
          }
          .pagination-nav-text:hover {
            color: #800000;
          }
          .activate-delete-button {
            background: black;
            color: white;
          }

          .manage-payment-body-content {
            width: 100%;
          }
          .manage-payment-body-content-listings-container {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 70px;
            // gap: 21px;
          }

          .manage-payment-body-content-top-actions {
            display: flex;
            gap: 40px;
            margin-bottom: 24px;
          }
          .top-actions-checkbox-container {
            padding: 5px;
            border: 1px solid #d4d4d4;
            border-radius: 4px;
          }
          .pagination-nav-text {
            font-family: "DomaineSansText-Light";
          }
          .pagination-nav-text:hover {
            color: #800000;
          }
          .activate-delete-button {
            background: black;
            color: white;
          }

          .no-listings-to-show {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 500px;
            font-weight: bold;
            font-size: 18px;
          }
          .no-listings-to-show-link {
            text-decoration: underline;
          }
          .no-listings-to-show-link:hover {
            cursor: pointer;
            color: #800000;
          }
          .ant-select-item-option-selected:not(
              .ant-select-item-option-disabled
            ) {
            background: white;
          }

          .manage-payment-payment-summary-section {
            display: flex;
            justify-content: space-between;
            width: 88%;
          }
          .payment-summary-grey-box {
            background: #f9f9f9;
            padding: 24px;
          }
          .payment-summary-pink-box {
            background: rgba(128, 0, 0, 0.05);
            padding: 24px;
            width: 450px;
          }
          .payment-summary-big-number {
            font-size: 24px;
            font-weight: bold;
            font-family: "DomaineSansText-Black";
            margin-top: 8px;
          }
          .payment-summary-pink-box-button {
            background: black;
            color: white;
            height: 47px;
            font-size: 16px;
            margin-top: 4px;
          }
          .payment-summary-underline-link {
            margin-top: 10px;
            padding: 16px 0;
            border-top: 1px solid #d4d4d4;
            font-size: 12px;
            color: #6f6f6f;
            text-decoration: underline;
          }
          .manage-payment-body-content-section-title {
            font-size: 18px;
            margin-bottom: 16px;
            font-weight: bold;
          }

          .manage-payment-body-content-trade-section-title {
            font-size: 18px;
            font-weight: bold;
          }

          . .manage-payment-body-content-section-subtitle {
            font-size: 16px;
          }

          .manage-payment-body-content-section {
            margin-top: 24px;
            background: white;
            padding: 40px 24px;
          }
          .recent-transaction-image {
            background-repeat: no-repeat;
            width: 40px;
            height: 40px;
            background-size: cover;
            border-radius: 50%;
          }
          .ant-input-search
            > .ant-input-group
            > .ant-input-group-addon:last-child
            .ant-input-search-button {
            background: #000;
            border: 1px solid #000;
            color: #fff;
            height: 48px;
            font-family: "DomaineSansText-Light";
            padding: 0 24px;
          }
          .ant-input-search
            .ant-input-group
            .ant-input-affix-wrapper:not(:last-child) {
            height: 48px;
          }

          .images-section {
            display: flex;
            gap: 16px;
          }
          .images-thumbnail-container {
            display: flex;
            gap: 8px;
            align-items: center;
          }
          .images-thumbnail-container div {
            width: 140px;
            height: 140px;
            background-size: cover;
            background-repeat: no-repeat;
          }
          .image-large {
            width: 250px;
            height: 250px;
            background-image: url(${product?.image1});
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;
          }
          .txt-product-status {
            text-transform: capitalize;
          }
          .product-image-tag-label {
            position: absolute;
            bottom: 0;
            text-align: center;
            width: 100%;
            background: #0000004d;
            font-size: 28px;
            color: white;
          }
          .product-main-container {
            display: flex;
            gap: 16px;
          }
          .product-content-section {
            display: flex;
            flex-direction: column;
            gap: 6px;
          }
          .product-color-section {
            display: flex;
            gap: 8px;
            align-items: center;
          }
          .product-color-section-box {
            width: 14px;
            height: 14px;
            border-radius: 50%;
          }
          .product-designer {
            font-family: "DomaineSansText-Regular";
            font-size: 18px;
            font-weight: bold;
          }
          .product-extra-container {
            display: flex;
            margin-top: 40px;
            border-top: 1px solid #d4d4d4;
            padding-top: 40px;
            gap: 4px;
          }
          .product-extra {
            width: 24%;
          }
          .product-extra-divider {
            border-left: 1px solid #d4d4d4;
            margin: 0 8px;
          }
          .product-extra-title {
            font-size: 16px;
            font-family: "DomaineSansText-Regular";
            margin-bottom: 8px;
          }
          .product-extra-details {
            font-size: 12px;
            margin-bottom: 8px;
          }
          .store-preview-status {
            position: absolute;
            right: 24px;
            top: 40px;
            font-size: 16px;
            font-family: "DomaineSansText-Light";
            padding: 16px 32px;
            border: 0;
          }
          .preview-main-content-header {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid rgb(212, 212, 212);
            padding-bottom: 16px;
            align-items: center;
          }
          .preview-main-content {
            margin: 16px 0 0;
          }
          .preview-main-content-row {
            padding: 16px 0;
            display: grid;
            grid-template-columns: 30% 1fr;
            grid-gap: 24px;
          }
          .preview-main-content-row-data {
            font-family: "DomaineSansText-Regular";
            width: 90%;
          }

          .txt-type {
            text-transform: capitalize;
          }
          .preview-button-black {
            height: 48px;
            background: black;
            color: white;
            font-size: 16px;
            width: 30%;
          }
          .preview-button-white {
            height: 48px;
            color: black;
            background: white;
            font-size: 16px;
            width: 30%;
          }
          .color-container {
            padding: 8px;
            border: 1px solid black;
            width: 120px;
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: center;
            white-space: nowrap;
            margin: 0 auto;
          }
          .color-hex-box {
            width: 12px;
            height: 12px;
            border-radius: 50%;
          }
          .table-quantity-container .ant-input[disabled] {
            color: #000;
            background-color: #fff;
          }

          .rejectListingModal {
            font-family: "DomaineSansText-Light";
          }
          .rejectListingModal.ant-modal-body {
            padding: 40px 40px 60px;
            color: black;
          }
          .reject-btn-save {
            background: #000000;
            border-color: #000;
            color: #fff;
            height: 48px;
            width: 100%;
          }
          .reject-btn-cancel {
            color: #000000;
            border-color: #000;
            background: #fff;
            height: 48px;
            width: 100%;
          }
          .input {
            height: 48px;
          }
          .ant-form-item-explain-error {
            margin-top: 4px;
          }

          .rejectListingSuccessModal .ant-modal-body {
            padding: 60px 40px;
            color: black;
            font-size: 18px;
            font-family: "DomaineSansText-Light";
          }
        `}</style>
      </div>
    </Dashboard>
  );
};

export default ListingPreview;

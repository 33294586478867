import { useEffect, useRef } from "react";
import { Input, Select } from "antd";
import { SearchOutlined, RightOutlined, LeftOutlined } from "@ant-design/icons";
// import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { ordersActionCreators } from "../../redux/orders";
import OrderView from "../seller/OrderView";
import Dashboard from "./Dashboard";

const { Search } = Input;

const ManageOrders = () => {
  const dispatch = useDispatch();
  const topPageRef = useRef(null);

  const type = window.location.pathname.split("/")[2];

  const { info } = useSelector((state) => state.user);
  const { ordersObj, orderStats } = useSelector((state) => state.orders);

  const data = ordersObj?.data;
  const meta = ordersObj?.meta;

  const {
    fetchAllOrders,
    fetchNewOrders,
    fetchFlaggedOrders,
    fetchNextOrders,
    fetchOrderStats,
  } = bindActionCreators(ordersActionCreators, dispatch);

  const scrollToTop = () =>
    topPageRef.current.scrollIntoView({ behavior: "smooth" });

  let currKey;

  if (type === "all") currKey = 24;
  if (type === "new") currKey = 25;

  const fetchOrderType = () => {
    if (type === "all") return fetchAllOrders(info.token);
    if (type === "new") return fetchNewOrders(info.token);
    if (type === "flagged") return fetchFlaggedOrders(info.token);
  };

  useEffect(() => {
    fetchOrderType();
    fetchOrderStats(info.token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <Dashboard
      headerTitle="The Designer Management Portal"
      // //headerSubtitle="Get inisights on all users using your app"
      currentPageKey={currKey}
    >
      <div className="dashboard-container">
        <div className="manage-listing-all-content">
          <div className="dashboard-coverall-container">
            {/* <div className="dashboard-adspace"></div> */}

            <div className="dashboard-body-main">
              <div className="dashboard-body-content-header">
                <div className="dashboard-body-content-header-container">
                  <div
                    className="dashboard-body-content-header-title"
                    fef={topPageRef}
                  >
                    Manage Orders
                  </div>
                  <div className="dashboard-body-content-header-subtitle">
                    View and edit your listed items here
                  </div>
                </div>
              </div>
              <div className="dashboard-body-container">
                <div className="dashboard-body-content">
                  <div className="dashboard-body-bold-numbers-container">
                    <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-earned">
                      <div className="dashboard-body-bold-numbers-item-number">
                        {orderStats?.newOrder}
                      </div>
                      <div className="dashboard-body-bold-numbers-item-text">
                        NEW ORDERS IN 24 HOURS
                      </div>
                    </div>
                    {/* <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-listed">
                      <div className="dashboard-body-bold-numbers-item-number">
                        {orderStats?.approvedOrder}
                      </div>
                      <div className="dashboard-body-bold-numbers-item-text">
                        APPROVED ORDERS
                      </div>
                    </div> */}
                    <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-sold">
                      <div className="dashboard-body-bold-numbers-item-number">
                        {orderStats?.rejectedOrder}
                      </div>
                      <div className="dashboard-body-bold-numbers-item-text">
                        FLAGGED ORDERS
                      </div>
                    </div>
                    <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-sold">
                      <div className="dashboard-body-bold-numbers-item-number">
                        {orderStats?.sentItem}
                      </div>
                      <div className="dashboard-body-bold-numbers-item-text">
                        COMPLETED ORDERS
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 40,
                    }}
                  >
                    <div style={{ width: "50%", height: "48px" }}>
                      <Search
                        className="search"
                        allowClear
                        enterButton="Search"
                        // onSearch={onSearch}
                        placeholder="Enter Order ID Or Designer Name"
                        prefix={<SearchOutlined />}
                      />
                    </div>
                    <div>
                      <Select
                        placeholder="Sort"
                        className="manage-orders-body-sidebar-content-select"
                      >
                        <Select.Option value="latest">
                          Most Recent
                        </Select.Option>
                        <Select.Option value="price">Price</Select.Option>
                        <Select.Option value="alphabetical">A-Z</Select.Option>
                      </Select>
                    </div>
                  </div>

                  <div className="dashboard-body-orders-section">
                    <div className="dashboard-body-orders-title">
                      {type} Orders
                    </div>

                    <div className="dashboard-body-content-listings-container">
                      {data?.length > 0 ? (
                        <>
                          {data?.map((order) => (
                            <OrderView
                              key={order.sale_id}
                              product={order}
                              type="Dashboard"
                            />
                          ))}
                          <div className="rows-box">
                            <p
                              className="txt-next"
                              style={{
                                color:
                                  meta?.current_page !== 1
                                    ? "black"
                                    : "#d9d9d9",
                                justifyContent: "flex-start",
                              }}
                            >
                              <LeftOutlined
                                onClick={() => {
                                  fetchNextOrders(
                                    info.token,
                                    meta?.prev_page_url
                                  );
                                  scrollToTop();
                                }}
                              />
                              <span
                                onClick={() => {
                                  fetchNextOrders(
                                    info.token,
                                    meta?.prev_page_url
                                  );
                                  scrollToTop();
                                }}
                              >
                                Previous
                              </span>
                            </p>

                            <p className="num-text">
                              Page {meta?.current_page} of{" "}
                              {Math.ceil(meta?.total / meta?.per_page)}
                            </p>

                            <p
                              className="txt-next"
                              style={{
                                color:
                                  meta?.current_page !==
                                  Math.ceil(meta?.total / meta?.per_page)
                                    ? "black"
                                    : "#d9d9d9",
                                justifyContent: "flex-end",
                              }}
                            >
                              <span
                                onClick={() => {
                                  fetchNextOrders(
                                    info.token,
                                    meta?.next_page_url
                                  );
                                  scrollToTop();
                                }}
                              >
                                Next
                              </span>
                              <RightOutlined
                                onClick={() => {
                                  fetchNextOrders(
                                    info.token,
                                    meta?.next_page_url
                                  );
                                  scrollToTop();
                                }}
                              />
                            </p>
                          </div>
                        </>
                      ) : (
                        <div className="no-listings-to-show">
                          <div>
                            <div>No Orders AVailable</div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <HelpFeedback />

      <PhoneVerificationModal
        modalVisible={phoneVerifyModalVisible}
        setModalVisible={setPhoneVerifyModalVisible}
      /> */}

        <style jsx="true">{`
          .dashboard-container {
            font-family: DomaineSansText-Light;
          }

          .manage-listing-all-content {
            position: relative;
          }
          .dashboard-coverall-container {
          }
          .dashboard-body-main {
            padding-bottom: 40px;
            margin-top: 16px;
          }
          .dashboard-adspace {
            background-image: url("images/seller-stats-banner.png");
            height: 211px;
            background-repeat: no-repeat;
            width: 80%;
            background-position: bottom;
            background-color: black;
            margin: auto;
          }
          .dashboard-body-content-header-container {
          }
          .dashboard-body-content-header {
            display: flex;
            justify-content: space-between;
          }
          .dashboard-body-content-header-title {
            font-size: 24px;
            font-weight: bold;
          }
          .dashboard-body-content-header-subtitle {
            font-size: 14px;
            color: #707070;
          }
          .dashboard-body-bold-numbers-container {
            display: flex;
            gap: 30px;
            width: 100%;
            margin-bottom: 50px;
            margin-top: 24px;
          }
          .dashboard-body-bold-numbers-item {
            width: 32%;
            background: black;
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 30px 10px;
          }
          .dashboard-body-bold-numbers-item-number {
            font-family: "DomaineSansText-Black";
            font-size: 24px;
          }
          .dashboard-body-bold-numbers-item-text {
            font-size: 12px;
          }
          .dashboard-body-content-listings-container {
            display: flex;
            flex-wrap: wrap;
          }
          .dashboard-body-alert-box-container {
            margin-bottom: 50px;
          }
          .dashboard-body-alert-box {
            margin: 16px 0;
            background: #800000;
            color: white;
            padding: 16px 10px;
            width: 100%;
          }
          .dashboard-body-alert-box-title {
            font-size: 18px;
            text-decoration: underline;
            margin-bottom: 8px;
          }
          .alert-box-dispute {
            font-size: 14px;
          }
          .dashboard-body-orders-section {
            margin-bottom: 50px;
          }
          .dashboard-body-orders-title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 24px;
            padding-left: 5px;
            text-transform: capitalize;
          }
          .dashboard-body-community-container {
            background: #ffecb3;
            padding: 16px 10px;
            width: 100%;
            margin-bottom: 50px;
          }
          .dashboard-body-community-title {
            font-size: 20px;
            font-weight: bold;
          }
          .dashboard-body-events-section {
            margin-bottom: 50px;
          }
          .dashboard-body-events-title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 8px;
          }
          .dashboard-body-events-subtitle {
            margin-bottom: 24px;
            width: 90%;
          }
          .dashboard-body-partners-section {
            margin-bottom: 50px;
          }
          .dashboard-body-quick-action-button {
            height: 48px;
            background: black;
            color: white;
            font-size: 16px;
            width: 240px;
          }
          .ant-select-item-option-selected:not(
              .ant-select-item-option-disabled
            ) {
            background: white;
          }
          .dashboard-body-link {
            text-decoration: underline;
            color: black;
          }
          .dashboard-store-link-section {
            margin-top: 24px;
            background: rgba(0, 0, 0, 0.04);
            padding: 25px 10px;
            width: 100%;
          }
          .dashboard-store-link-title {
            font-size: 18px;
            margin-bottom: 8px;
            font-weight: bold;
          }
          .dashboard-store-link-subtitle {
            width: 90%;
          }
          .dashboard-events-image {
            width: auto;
            height: 200px;
          }
          .dashboard-partners-image {
            width: auto;
            height: 200px;
          }
          .rows-box {
            display: flex;
            justify-content: space-between;
            margin-top: 2rem;
            width: 100%;
          }
          .num-text {
            color: #000000;
            flex-basis: 33%;
            text-align: center;
          }
          .txt-next {
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 24px;
            flex-basis: 33%;
          }
          .dashboard-body-partners-images-container {
            display: flex;
            gap: 30px;
          }
          .dashboard-body-quick-action-button-container {
            display: flex;
            gap: 16px;
            flex-wrap: wrap;
            margin-bottom: 50px;
          }
          .dashboard-body-quick-action-title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 8px;
          }
          .ant-input-search
            > .ant-input-group
            > .ant-input-group-addon:last-child
            .ant-input-search-button {
            background: #000;
            border: 1px solid #000;
            color: #fff;
            height: 48px;
            font-family: "DomaineSansText-Light";
            padding: 0 24px;
          }
          .ant-input-search
            .ant-input-group
            .ant-input-affix-wrapper:not(:last-child) {
            height: 48px;
            border-color: black;
          }
          .manage-orders-body-sidebar-content-select .ant-select-selector {
            margin: 0 !important;
            height: 48px !important;
            font-size: 14px !important;
            padding: 0 16px !important;
            width: 150px !important;
            border-color: black !important;
          }
          .manage-orders-body-sidebar-content-select .ant-select-arrow {
            top: 50%;
            height: auto;
            width: auto;
          }
          .manage-orders-body-sidebar-content-select.ant-select-single
            .ant-select-selector
            .ant-select-selection-item,
          .ant-select-single
            .ant-select-selector
            .ant-select-selection-placeholder {
            line-height: 3;
          }

          @media (max-width: 640px) {
            .dashboard-coverall-container {
              margin-left: 0;
            }
            .dashboard-adspace {
              background-image: url(images/seller-stats-banner.png);
              height: 120px;
              background-repeat: no-repeat;
              width: 100%;
              background-position: center;
              background-color: black;
              margin: auto;
              background-size: cover;
            }

            .hello-store-mobile {
              padding: 0px 16px 16px;
              font-weight: bold;
              font-size: 18px;
            }
            .dashboard-body-main {
              padding: 0 16px;
              padding-bottom: 40px;
              margin-right: 0;
            }
            .dashboard-body-content-header-container {
              display: flex;
              align-items: center;
            }
            .dashboard-body-content-header-title {
              font-size: 18px;
            }
            .dashboard-body-quick-action-button {
              padding: 0 16px;
              height: 40px;
              font-size: 14px;
              margin-top: auto;
            }
            .dashboard-body-bold-numbers-item {
              width: auto;
              justify-content: center;
              padding: 16px;
              text-align: center;
            }
            .dashboard-body-bold-numbers-container {
              gap: 8px;
              margin-bottom: 24px;
              margin-top: 0px;
              flex-wrap: wrap;
            }
            .dashboard-body-bold-numbers-item-number {
              font-size: 16px;
            }
            .dashboard-body-bold-numbers-item-text {
              font-size: 10px;
            }
            .dashboard-body-alert-box {
              padding: 16px 10px;
              margin-bottom: 24px;
            }
            .dashboard-body-community-container {
              padding: 16px 10px;
              margin-bottom: 24px;
            }
            .dashboard-body-events-subtitle {
              margin-bottom: 16px;
              width: 100%;
              font-size: 14px;
            }
            .dashboard-body-orders-section {
              margin-bottom: 24px;
            }
            .dashboard-partners-image {
              width: auto;
              height: 120px;
            }
            .dashboard-body-partners-images-container {
              gap: 8px;
            }
            .dashboard-store-link-section {
              padding: 16px 10px;
              margin-bottom: 24px;
            }
            .dashboard-events-image {
              width: auto;
              height: 300px;
            }
            .process-icon-container {
              padding: 8px;
              border-radius: 50%;
              border: 1px solid #800000;
              width: 32px;
              height: 32px;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }
            .process-icon-div-mobile {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 24px;
              margin: 16px 0;
            }
            .process-icon {
              color: #800000;
            }
            .dashboard-body-content-listings-container {
              display: block;
              margin-bottom: 40px;
            }
            .dashboard-body-bold-numbers-item-earned {
              width: 100%;
            }
            .dashboard-body-bold-numbers-item-listed,
            .dashboard-body-bold-numbers-item-sold {
              flex: 2;
            }
          }
        `}</style>
      </div>
    </Dashboard>
  );
};

export default ManageOrders;

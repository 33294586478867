import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { userActionCreators } from "../../redux/user";
import { Button, Form, Input, Row, Col, Select, message } from "antd";
import HeaderComponent from "../../components/Header";

const { Option } = Select;

const AddAdmin = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { registerAdmin } = bindActionCreators(userActionCreators, dispatch);
  const user = useSelector((state) => state.user);

  const onSubmit = async (values) => {
    const res = await registerAdmin(user.info.token, values);
    if (res.status === "ok") {
      form.resetFields();
      return message.success(res.message);
    }
    if (res.status === "not ok") {
      return message.error(res.message);
    }
  };

  return (
    <div>
      <HeaderComponent />
      <div className="dashboard-welcome-container">
        <div className="dashboard-welcome-title">Hello {user?.info?.fname}</div>
        {/* <div className="dashboard-welcome-subtitle">
          Welcome To Your Admin Account
        </div> */}
      </div>
      <div className="form-container">
        <h1 className="txt-add">Add Admin</h1>
        <p className="txt-desc">Add new administrators to the super admin</p>
        <Form
          className="form"
          form={form}
          layout="vertical"
          name="add-admin"
          onFinish={onSubmit}
          requiredMark={false}
          scrollToFirstError
        >
          <Row gutter={80}>
            <Col span={12}>
              <Form.Item
                name="firstname"
                label="First Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your first Name!",
                  },
                ]}
              >
                <Input className="input" placeholder="Enter your first name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="lastname"
                label="Last Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your last Name!",
                  },
                ]}
              >
                <Input className="input" placeholder="Enter your lirst name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={80}>
            <Col span={12}>
              <Form.Item
                name="email"
                label="Email Address"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please enter your email address",
                  },
                ]}
              >
                <Input
                  className="input"
                  placeholder="Enter your email address"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Enter your password",
                  },
                ]}
              >
                <Input
                  className="input"
                  type="password"
                  placeholder="Create Password"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={80}>
            <Col span={12}>
              <Form.Item
                name="confirm_password"
                label="Confirm Password"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        "The two passwords that you entered do not match!"
                      );
                    },
                  }),
                ]}
              >
                <Input
                  className="input"
                  type="password"
                  placeholder="Confirm Password"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="role"
                label="Select Portal"
                rules={[
                  {
                    required: true,
                    message: "Please select portal",
                  },
                ]}
              >
                <Select
                  className="select"
                  placeholder="Select portal to assign"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="1">The Super Admin Portal</Option>
                  <Option value="2">The Designer Management Portal</Option>
                  <Option value="3">The Customer Management Portal</Option>
                  <Option value="4">The Metrics Portal</Option>
                  <Option value="5">The Finance Management Portal</Option>
                  <Option value="6">The Logistics portal</Option>
                  <Option value="7">The Marketing portal</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Col span={12} style={{ margin: "auto" }}>
            <Form.Item>
              <Button className="btn-add" type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </div>
      <style jsx="true">{`
        .dashboard-welcome-container {
          padding: 40px 10%;
          font-family: "DomaineSansText-Regular";
          background: url("/images/acct-bg.jpeg");
          background-repeat: no-repeat;
          background-size: cover;
          margin-top: 80px;
        }
        .dashboard-welcome-title {
          font-size: 24px;
          font-weight: bold;
        }
        .dashboard-welcome-subtitle {
          font-size: 16px;
        }
        .logo-container {
          margin: 25px 28px;
        }
        .logo {
          width: 148px;
        }
        .form-container {
          padding: 40px 10%;
          font-family: "DomaineSansText-Light";
          max-width: 1660px;
          margin: auto;
        }
        .form {
          margin-top: 28.52px;
        }
        .txt-add {
          font: normal normal 24px/39px DomaineSansText-Light;
          margin: 0px;
        }
        .txt-desc {
          color: #707070;
          font: normal normal 16px/23px DomaineSansText-Light;
          margin: 0px;
        }
        .input {
          border: 1px solid #707070;
          height: 48px;
        }
        .input:hover {
          border: 1px solid #707070;
        }
        .ant-input-affix-wrapper > input.ant-input {
          color: #000;
        }
        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector {
          border: 1px solid #707070;
          height: 48px;
          padding: 0px;
        }
        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow
          .ant-select-selection-placeholder {
          display: flex;
          padding: 18px;
          line-height: 1;
        }
        .ant-select-selector:hover {
          border: 1px solid #707070;
        }
        .btn-add {
          background: #000000;
          border: 1px solid #000;
          color: #ffffff;
          font-size: 18px;
          height: 48px;
          width: 100%;
          margin-top: 40px;
        }
        .btn-add:hover {
          background: #fff;
          border: 1px solid #000;
          color: #000;
        }
        .ant-form-item-explain.ant-form-item-explain-error {
          margin-top: 4px;
        }
      `}</style>
    </div>
  );
};

export default AddAdmin;

import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Form,
  Button,
  Input,
  Select,
  Row,
  Col,
  message,
  DatePicker,
  Radio,
  Tooltip,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { brandActionCreators } from "../../redux/seller";
import axios from "axios";
import Dashboard from "./Dashboard";
import getLatLng from "../../utils/getLatLng";
import { getBanks } from "../../api/brandsApi";

// import getLatLng from "../../utils/getLatLng";
// import { useMediaQuery } from "react-responsive";

const { Option } = Select;

const CreateSeller = () => {
  const dispatch = useDispatch();
  const [userForm] = Form.useForm();
  const [storeForm] = Form.useForm();
  const [bankForm] = Form.useForm();
  const history = useHistory();

  const { info } = useSelector((state) => state.user);

  const { createStore } = bindActionCreators(brandActionCreators, dispatch);

  const [storeInfo, setStoreInfo] = useState({});
  const [banks, setBanks] = useState([]);
  const [imageUrl, setUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const storeDiv = useRef(null);
  const bankDiv = useRef(null);
  const hiddenFileInput = useRef(null);

  const [countryValue, setCountryValue] = useState("Nigeria");

  const [nigeriaData, setNigeriaData] = useState([]);
  const [lagosData, setLagosData] = useState([]);
  const [UKData, setUKData] = useState([]);
  const [USAData, setUSAData] = useState([]);

  const [nigeriaStateValue, setNigeriaStateValue] = useState("");

  const categories = ["Shoes", "Bags", "Skin Care", "Clothing", "Accessory"];

  const getAllBanks = async () => setBanks(await getBanks(info.token));

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    const fileUploaded = event.target.files[0];
    setFileName(fileUploaded.name);
    uploadImage(fileUploaded);
  };

  const uploadImage = async (file) => {
    const data = new FormData();
    data.append("file", file);
    data.append("api_key", "693411225724272");
    data.append("upload_preset", "barners");
    data.append("cloud_name", "losode-ng");
    const result = await axios.post(
      `https://api.cloudinary.com/v1_1/losode-ng/image/upload`,
      data
    );
    setUrl(result.data.secure_url);
    return result.data.secure_url;
  };

  const onSubmitStoreDetails = async () => {
    const { country, dob, street, phoneNumber } = storeForm.getFieldsValue();
    const values = {
      address: street,
      dob: dob.format("YYYY-MM-DD"),
    };
    const latLng = await getLatLng(values);
    const latitude = latLng.split(",")[0];
    const longitude = latLng.split(",")[1];
    const currentPhone = phoneNumber;

    if (country === "Nigeria") {
      const intPhoneNG = `234` + currentPhone.substring(1);
      storeForm.setFieldsValue({ phoneNumber: intPhoneNG });
    }
    if (country === "United Kingdom") {
      const intPhoneUK = `44` + currentPhone.substring(1);
      storeForm.setFieldsValue({ phoneNumber: intPhoneUK });
    }
    if (country === "United States") {
      const intPhoneUS = `1` + currentPhone.substring(1);
      storeForm.setFieldsValue({ phoneNumber: intPhoneUS });
    }

    const data = {
      ...storeForm.getFieldsValue(),
      ...values,
      brandLogo: imageUrl,
      latitude,
      longitude,
    };
    setStoreInfo(data);
    bankDiv.current?.scrollIntoView({ behavior: "smooth" });
  };

  const onSubmitUserDetails = async () => {
    storeDiv.current?.scrollIntoView({ behavior: "smooth" });
  };

  const onSubmitBankDetails = async (values) => {
    const data = { ...storeInfo, ...userForm.getFieldsValue(), ...values };

    const res = await createStore(info.token, data);
    if (res.status === "ok") {
      message.success(res.message);
      history.push("/brands");
    }
    if (res.status === "not ok") {
      message.error(res.message);
    }
  };

  useEffect(() => {
    storeForm.setFieldsValue({
      brandLogo: fileName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileName]);

  useEffect(() => {
    const fetchAreas = async () => {
      const result = await axios.get(`utility/countries`);
      if (result.data.state === "ok") {
        const ng = result.data.data.filter(
          (country) => country.name === "Nigeria"
        );
        setNigeriaData(ng);

        const uk = result.data.data.filter(
          (country) => country.name === "United Kingdom"
        );
        setUKData(uk);

        const us = result.data.data.filter(
          (country) => country.name === "United States of America"
        );
        setUSAData(us);

        if (ng) {
          const lg = ng[0].states.filter((data) => data.state_name === "Lagos");
          setLagosData(lg);
        }
      }
    };
    getAllBanks();
    fetchAreas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  const isTabletOrMobile = false;

  // Area is select for Lagos and input for other states
  // State and Area only for Nigeria
  // Information icon

  return (
    <>
      <Dashboard
        headerTitle="The Designer Management Portal"
        //headerSubtitle="Get inisights on all users using your app"
      >
        <div className="create-store-container">
          <div className="account-container">
            <div
              style={{ margin: "16px 0 24px", cursor: "pointer" }}
              onClick={() => history.goBack()}
            >
              <svg
                width="28"
                height="10"
                viewBox="0 0 28 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.94997 5.35647C0.994502 5.47131 1.0613 5.57678 1.14684 5.66583L4.89684 9.41583C5.07263 9.59278 5.31285 9.69356 5.56248 9.69356C5.81211 9.69356 6.05232 9.59278 6.22812 9.41583C6.40508 9.24004 6.50586 8.99982 6.50586 8.75019C6.50586 8.50056 6.40508 8.26035 6.22812 8.08455L4.07187 5.93769H26.6562C27.1742 5.93769 27.5937 5.51817 27.5937 5.00019C27.5937 4.48221 27.1742 4.06269 26.6562 4.06269H4.07187L6.22812 1.91583C6.5961 1.54785 6.5961 0.952559 6.22812 0.584579C5.86014 0.216599 5.26485 0.216599 4.89687 0.584579L1.14687 4.33458C1.06133 4.42364 0.994532 4.52911 0.95 4.64394C0.901952 4.75644 0.876173 4.87714 0.875 5.00019C0.876172 5.12324 0.901953 5.24394 0.95 5.35644L0.94997 5.35647Z"
                  fill="black"
                />
              </svg>

              <span
                style={{
                  fontFamily: "DomaineSansText-Light",
                  marginLeft: "8px",
                }}
              >
                Back To Designer Portal
              </span>
            </div>
            <h1 className="seller-welcome-txt-title">Add A New Designer</h1>
            {/* <p className="seller-welcome-txt-more">
                We are excited to see you join! You are well on your way to owning
                a business that trades globally. Fill in the form below to create
                your store and start selling to millions of ready buyers
              </p> */}
            {/* <hr className="seller-welcome-line" /> */}
            <Form
              form={userForm}
              name="userForm"
              onFinish={onSubmitUserDetails}
              scrollToFirstError
              layout="vertical"
              requiredMark={false}
              style={{
                padding: "16px 32px",
                background: "white",
                margin: "24px 0",
              }}
            >
              <div className="form-section-title">Personal Information</div>
              <Row className="form-row" gutter={[48, 12]}>
                <Col span={12}>
                  <Form.Item
                    name="firstname"
                    label="First Name"
                    rules={[
                      {
                        required: true,
                        message: "This is a required field!",
                      },
                    ]}
                  >
                    <Input className="input" placeholder="Enter first name" />
                  </Form.Item>
                </Col>
                <Col span={!isTabletOrMobile ? 12 : 24}>
                  <Form.Item
                    name="lastname"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                        message: "This is a required field!",
                      },
                    ]}
                  >
                    <Input className="input" placeholder="Enter last name" />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="form-row" gutter={[48, 12]}>
                <Col span={12}>
                  <Form.Item
                    name="email"
                    label="Email Address"
                    rules={[
                      {
                        required: true,
                        message: "This is a required field!",
                      },
                    ]}
                  >
                    <Input
                      className="input"
                      placeholder="Enter your email address"
                    />
                  </Form.Item>
                </Col>
                <Col span={12} style={{ textAlign: "center", margin: "auto" }}>
                  <Form.Item>
                    <Button
                      className="seller-welcome-btn-submit"
                      type="primary"
                      style={{ width: "100%" }}
                      htmlType="submit"
                    >
                      Next
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <div ref={storeDiv}>
              <Form
                form={storeForm}
                initialValues={{ country: "Nigeria" }}
                name="storeForm"
                onFinish={onSubmitStoreDetails}
                scrollToFirstError
                layout="vertical"
                requiredMark={false}
                style={{
                  padding: "16px 32px",
                  background: "white",
                  margin: "24px 0",
                }}
              >
                <div className="form-section-title">Designer Information</div>
                <Col
                  span={!isTabletOrMobile ? 12 : 24}
                  style={{ margin: "auto" }}
                >
                  <Form.Item
                    name="country"
                    label="Country"
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                  >
                    <Select
                      className="select"
                      placeholder="Select Country"
                      optionFilterProp="children"
                      defaultValue="Nigeria"
                      value={countryValue}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => setCountryValue(value)}
                    >
                      <Option value="Nigeria">Nigeria</Option>
                      <Option value="USA">United States</Option>
                      <Option value="UK">United Kingdom</Option>
                    </Select>
                  </Form.Item>
                </Col>

                {/* <h2 className="seller-welcome-txt-about">About your business</h2>
                <p className="seller-welcome-txt-info">
                  To get you up and running quickly. We need some information from
                  you that will help us verify that you are a legitimate brand or
                  business
                </p> */}

                <Row className="form-row" gutter={[48, 12]}>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="brandName"
                      label="Designer Name"
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                      ]}
                    >
                      <Input
                        className="input"
                        placeholder="Enter your business or designer name"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="brandLogo"
                      label="Upload Designer Logo"
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                      ]}
                    >
                      <Input
                        className="input"
                        placeholder="Use a professionally designed logo"
                        onChange={uploadImage}
                        suffix={
                          <>
                            <Button
                              className="btn-upload"
                              onClick={handleClick}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                  alignItems: "end",
                                }}
                              >
                                <img alt="upload" src="/images/upload.png" />
                                <span
                                  style={{
                                    fontSize: `${
                                      !isTabletOrMobile ? "14px" : "10px"
                                    }`,
                                  }}
                                >
                                  Browse
                                </span>
                              </div>
                            </Button>
                            <input
                              accept="image/*"
                              type="file"
                              ref={hiddenFileInput}
                              onChange={handleChange}
                              style={{ display: "none" }}
                            />
                          </>
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="description"
                      label="Brand Description"
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                      ]}
                    >
                      <Input
                        className="input"
                        placeholder="Tell us about your brand"
                      />
                    </Form.Item>
                  </Col>

                  {countryValue === "Nigeria" && (
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="state"
                        label="State"
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <Select
                          className="select"
                          placeholder="Select State"
                          onChange={(value) => setNigeriaStateValue(value)}
                        >
                          {nigeriaData[0]?.states?.map((state) => {
                            return (
                              <Option value={state.state_name}>
                                {state.state_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                  {countryValue === "USA" && (
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="state"
                        label="State"
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <Select className="select" placeholder="Select State">
                          {USAData[0]?.states?.map((state) => {
                            return (
                              <Option value={state.state_name}>
                                {state.state_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                  {countryValue === "UK" && (
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="state"
                        label="City"
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <Select className="select" placeholder="Select City">
                          {UKData[0]?.states?.map((state) => {
                            return (
                              <Option value={state.state_name}>
                                {state.state_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="street"
                      label="Street Address"
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                        {
                          max:45,
                          message:"Please limit your address to 45 characters or less"
                        }
                      ]}
                    >
                      <Input
                        className="input"
                        placeholder="19 East Drive, Agodi"
                        suffix={
                          <Tooltip title="Enter only your street address here, do not add state or country ">
                            <InfoCircleOutlined
                              style={{
                                color: "rgba(0,0,0,.45)",
                                fontSize: "20px",
                                marginRight: "20px",
                              }}
                            />
                          </Tooltip>
                        }
                      />
                    </Form.Item>
                  </Col>
                  {countryValue === "Nigeria" && nigeriaStateValue === "Lagos" && (
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        label="Area"
                        name="area"
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <Select className="select" placeholder="Select Area">
                          {lagosData[0]?.areas?.map((area) => {
                            return <Option value={area.id}>{area.name}</Option>;
                          })}
                        </Select>
                        {/* {nigeriaStateValue!=='Lagos' && <Input className="input" placeholder="Select area" />} */}
                      </Form.Item>
                    </Col>
                  )}
                  {countryValue === "Nigeria" && nigeriaStateValue !== "Lagos" && (
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        label="Area"
                        name="area"
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        {nigeriaStateValue !== "Lagos" && (
                          <Input className="input" placeholder="Enter Area" />
                        )}
                      </Form.Item>
                    </Col>
                  )}

                  {countryValue === "UK" && (
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        label="County"
                        name="area"
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <Input
                          className="input"
                          placeholder="Type in your county"
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {countryValue === "USA" && (
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        label="Area"
                        name="area"
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <Input
                          className="input"
                          placeholder="Type in your area"
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {countryValue === "Nigeria" && (
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item name="zipCode" label="Postal Code (Optional)">
                        <Input
                          className="input"
                          placeholder="Enter your postal code"
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {countryValue === "USA" && (
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="zipCode"
                        label="Zip Code"
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <Input
                          className="input"
                          placeholder="Enter your zip code"
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {countryValue === "UK" && (
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="zipCode"
                        label="Post Code"
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <Input
                          className="input"
                          placeholder="Enter your post code"
                        />
                      </Form.Item>
                    </Col>
                  )}

                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      label="Date Of Birth"
                      name="dob"
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                      ]}
                    >
                      <DatePicker className="date" placeholder="DD/MM/YYYY" />
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      label="Gender"
                      name="gender"
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value="male">Male</Radio>
                        <Radio value="female">Female</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="alternateEmail"
                      label="Alternate Email Address"
                      rules={[
                        {
                          type: "email",
                          message: "Use a valid email address",
                        },
                        {
                          required: true,
                          message: "Please input alternate email!",
                        },
                        // () => ({
                        //   validator(rule, value) {
                        //     if (value !== info.email) {
                        //       return Promise.resolve();
                        //     }

                        //     return Promise.reject(
                        //       "Please enter a different email address. This must be different from your login email address."
                        //     );
                        //   },
                        // }),
                      ]}
                    >
                      <Input
                        className="input"
                        suffix={
                          <Tooltip title="To ensure you do not miss any orders, we need to collect an alternative email address  ">
                            <InfoCircleOutlined
                              style={{
                                color: "rgba(0,0,0,.45)",
                                fontSize: "20px",
                                marginRight: "20px",
                              }}
                            />
                          </Tooltip>
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="businessRegistered"
                      label="Is your business registered?"
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                      ]}
                    >
                      <Select>
                        <Select.Option value="Yes">Yes</Select.Option>
                        <Select.Option value="No">No</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="brandCategory"
                      label="Tell us what your Store sells"
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                      ]}
                    >
                      <Select
                        className="select"
                        placeholder="Select all categories that relate to your Store"
                        mode="multiple"
                      >
                        {categories.map((cat) => {
                          return (
                            <Option value={cat} key={cat}>
                              {cat}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  {countryValue === "Nigeria" && (
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="phoneNumber"
                        label="Phone Number"
                        rules={[
                          {
                            required: true,
                            message: "Please input your phone Number!",
                          },
                          {
                            min: 11,
                            message:
                              "Please check the number of digits entered",
                          },
                          {
                            max: 11,
                            message:
                              "Please check the number of digits entered",
                          },
                        ]}
                      >
                        <Input
                          className="input"
                          type="number"
                          placeholder="Enter 11-digits"
                          suffix={
                            <Tooltip title="We need your phone number so that we can tell you about your orders. You will need to verify your phone number before you can start selling. You can still go ahead and create your store">
                              <InfoCircleOutlined
                                style={{
                                  color: "rgba(0,0,0,.45)",
                                  fontSize: "20px",
                                  marginRight: "20px",
                                }}
                              />
                            </Tooltip>
                          }
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {countryValue !== "Nigeria" && (
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="phoneNumber"
                        label="Phone Number"
                        rules={[
                          {
                            required: true,
                            message: "Please input your phone Number!",
                          },
                          {
                            max: 11,
                            message:
                              "Please check the number of digits entered",
                          },
                        ]}
                      >
                        <Input
                          className="input"
                          type="number"
                          placeholder="Enter phone number"
                          suffix={
                            <Tooltip title="We need your phone number so that we can tell you about your orders. You will need to verify your phone number before you can start selling. You can still go ahead and create your store">
                              <InfoCircleOutlined
                                style={{
                                  color: "rgba(0,0,0,.45)",
                                  fontSize: "20px",
                                  marginRight: "20px",
                                }}
                              />
                            </Tooltip>
                          }
                        />
                      </Form.Item>
                    </Col>
                  )}

                  {countryValue === "Nigeria" && (
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="regNumber"
                        label="Business Registration Number (Optional) "
                        rules={[]}
                      >
                        <Input
                          className="input"
                          placeholder="Enter your CAC number"
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {countryValue !== "Nigeria" && (
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="businessID"
                        label="Business Registration Number (Optional) "
                        rules={[]}
                      >
                        <Input
                          className="input"
                          placeholder="Enter your business registration number"
                        />
                      </Form.Item>
                    </Col>
                  )}

                  <Col span={24} style={{ textAlign: "center" }}>
                    <Form.Item>
                      <Button
                        className="seller-welcome-btn-submit"
                        type="primary"
                        htmlType="submit"
                      >
                        Next
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
        {/* ) : ( */}
        <div className="create-store-container" ref={bankDiv}>
          <div className="account-container">
            {/* <p className="seller-welcome-txt-more">
                We need to know where you would like to receive your payments.
                Losode only pays to your bank account. Bank account information
                must align to your base country’s currency. To change your payment
                information, send an email to our Seller Acquistion Team.
              </p>
              <hr className="seller-welcome-line" /> */}

            <Form
              form={bankForm}
              name="bankForm"
              onFinish={onSubmitBankDetails}
              scrollToFirstError
              layout="vertical"
              requiredMark={false}
              style={{
                padding: "16px 32px",
                background: "white",
                margin: "24px 0",
              }}
            >
              <div className="form-section-title">Payment Information</div>
              {countryValue === "Nigeria" ? (
                <Row className="form-row" gutter={[48, 12]}>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="bankName"
                      label="Bank Name"
                      rules={[
                        {
                          required: true,
                          message: "This is a required field!",
                        },
                      ]}
                    >
                      <Select className="select" placeholder="Select your Bank">
                        {banks?.map((bank, idx) => (
                          <Option value={bank.name} key={idx}>
                            {bank.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="accountName"
                      label="Account Name "
                      rules={[
                        {
                          required: true,
                          message: "This is a required field!",
                        },
                      ]}
                    >
                      <Input
                        className="input"
                        placeholder="Enter your account name"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="accountNumber"
                      label="Account Number"
                      rules={[
                        {
                          required: true,
                          message: "This is a required field!",
                        },
                      ]}
                    >
                      <Input
                        className="input"
                        placeholder="Enter your account number"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item>
                      <Button
                        className="btn-bank-submit"
                        type="primary"
                        htmlType="submit"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              ) : countryValue === "UK" ? (
                <Row className="form-row" gutter={[48, 12]}>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="bankName"
                      label="Bank Name"
                      rules={[
                        {
                          required: true,
                          message: "This is a required field!",
                        },
                      ]}
                    >
                      <Input
                        className="input"
                        placeholder="Enter your bank name"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="accountName"
                      label="Account Name "
                      rules={[
                        {
                          required: true,
                          message: "This is a required field!",
                        },
                      ]}
                    >
                      <Input
                        className="input"
                        placeholder="Enter your account name"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="sortCode"
                      label="Sort Code"
                      rules={[
                        {
                          required: true,
                          message: "This is a required field!",
                        },
                      ]}
                    >
                      <Input
                        className="input"
                        placeholder="Enter your sort code"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="accountNumber"
                      label="Account Number"
                      rules={[
                        {
                          required: true,
                          message: "This is a required field!",
                        },
                      ]}
                    >
                      <Input
                        className="input"
                        placeholder="Enter your account number"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={!isTabletOrMobile ? 12 : 24}
                    style={{ margin: "auto" }}
                  >
                    <Form.Item>
                      <Button
                        className="seller-welcome-btn-submit"
                        style={{ width: "100%" }}
                        type="primary"
                        // htmlType="submit"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                <Row className="form-row" gutter={[48, 12]}>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="bankName"
                      label="Bank Name"
                      rules={[
                        {
                          required: true,
                          message: "This is a required field!",
                        },
                      ]}
                    >
                      <Input
                        className="input"
                        placeholder="Enter your bank name"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="accountName"
                      label="Account Name "
                      rules={[
                        {
                          required: true,
                          message: "This is a required field!",
                        },
                      ]}
                    >
                      <Input
                        className="input"
                        placeholder="Enter your account name"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="routingNumber"
                      label="Routing Number"
                      rules={[
                        {
                          required: true,
                          message: "This is a required field!",
                        },
                      ]}
                    >
                      <Input
                        className="input"
                        placeholder="Enter your routing number"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={!isTabletOrMobile ? 12 : 24}>
                    <Form.Item
                      name="accountNumber"
                      label="Account Number"
                      rules={[
                        {
                          required: true,
                          message: "This is a required field!",
                        },
                      ]}
                    >
                      <Input
                        className="input"
                        placeholder="Enter your account number"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={!isTabletOrMobile ? 12 : 24}
                    style={{ margin: "auto" }}
                  >
                    <Form.Item>
                      <Button
                        className="seller-welcome-btn-submit"
                        type="primary"
                        style={{ width: "100%" }}
                        htmlType="submit"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Form>
          </div>
        </div>

        {/* )} */}
      </Dashboard>
      <style jsx="true">{`
        .create-store-container {
          background: rgb(249, 249, 249);
        }
        .account-container {
          margin: -40px;
          padding: 24px;
          background: #fff;
          padding: 40px 32px;
          background: #f7f7f7;
          min-height: calc(100vh - 80px);
        }
        .seller-welcome-txt-title {
          color: #000000;
          margin: 0;
          background: white;
          padding: 16px;
          padding-left: 32px;
          font-family: "DomaineSansText-Regular";
          font-size: 24px;
        }
        .seller-welcome-txt-more {
          font: normal normal 14px DomaineSansText-Light;
          color: #707070;
          width: 70%;
          text-align: center;
          margin: 8px auto;
        }
        .seller-welcome-line {
          border: 0.8px solid #b7b7b7;
          opacity: 0.63;
          width: 70%;
          margin: 32px auto;
        }
        .seller-welcome-txt-about {
          font: normal 600 24px/42px DomaineSansText-Regular;
          color: #000000;
          margin: 0;
          text-align: center;
        }
        .seller-welcome-txt-info {
          font: normal normal 14px DomaineSansText-Light;
          color: #707070;
          width: 70%;
          text-align: center;
          margin: 8px auto;
        }

        .ant-form-vertical .ant-form-item-label > label {
          font-size: 14px;
          font-family: "DomaineSansText-Light";
        }
        .ant-form-item-explain.ant-form-item-explain-error {
          margin-top: 8px;
        }
        .form-row {
          margin-top: 26.52px;
        }
        .input {
          height: 56px;
          border: 1px solid #707070;
          padding: 4px 0px 4px 16px;
          font-size: 16px;
          font-family: "DomaineSansText-Light";
        }
        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector,
        .ant-picker {
          height: 56px !important;
          width: 100%;
          border: 1px solid #707070;
        }
        .ant-select-show-search.ant-select:not(.ant-select-customize-input)
          .ant-select-selector {
          height: 56px !important;
          width: 100%;
          border: 1px solid #707070;
        }
        .ant-picker-input > input {
          font-size: 16px;
          font-family: "DomaineSansText-Light";
        }
        .ant-select,
        .ant-input-affix-wrapper > input.ant-input,
        span.ant-radio + * {
          font-size: 16px;
          font-family: "DomaineSansText-Light";
        }
        .ant-radio-group {
          height: 56px;
          display: flex;
          align-items: center;
        }
        .ant-radio-inner {
          width: 20px;
          height: 20px;
        }
        .ant-radio-wrapper {
          margin-right: 40px;
        }
        .ant-input-affix-wrapper > input.ant-input {
          color: #000;
        }
        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector {
          border: 1px solid #707070;
          height: 56px;
          padding: 0px;
        }

        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow
          .ant-select-selection-placeholder {
          display: flex;
          padding: 18px;
        }
        .btn-upload {
          margin-right: 0px !important;
        }
        .btn-upload,
        .btn-upload:hover {
          background: #000000;
          color: #fff;
          height: 55px;
          width: 130px;
        }
        .seller-welcome-btn-submit,
        .btn-bank-submit {
          background: #000;
          font-size: 18px;
          width: 48%;
          height: 56px;
          margin-top: 30px;
          font-family: "DomaineSansText-Light";
        }
        .btn-bank-submit {
          width: 100%;
        }
        .seller-welcome-btn-submit:hover,
        .btn-bank-submit:hover {
          background: #fff;
          border: 1px solid #000;
          color: #000;
        }
        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow
          .ant-select-selection-placeholder {
          padding: 13px;
        }
        .ant-tooltip-inner {
          font-size: 14px;
          font-family: "DomaineSansText-Light";
          background: #f4f4f4;
          color: black;
        }
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
          background-color: #e5e5e5;
        }
        .form-section-title {
          font-size: 18px;
          font-family: "DomaineSansText-Regular";
        }

        @media (max-width: 640px) {
          .account-container {
            margin: 12px 24px;
            margin-top: 52px;
          }
          .txt-title,
          .txt-about {
            font-size: 22px !important;
            font-weight: 700;
            text-align: center;
            width: 100%;
            line-height: 15px;
            font-family: DomaineSansText-Regular !important;
          }
          .txt-more,
          .txt-info {
            font-size: 10px;
            text-align: center;
            width: 100%;
            line-height: 15px;
          }
          .form-row {
            row-gap: 7px !important;
            margin-top: 5px !important;
          }
          .btn-upload {
            height: 40px;
            width: 86px;
            margin: 3px;
            padding: 5px;
          }
          .btn-upload span {
          }
          line-height: 1.2;

          .btn-upload img {
            height: 16px;
          }
          .btn-submit {
            background: #000000;
            width: 100%;
            margin-bottom: 130px;
            margin-top: 16px;
            font-size: 14px;
            font-family: DomaineSansText-Light;
          }
        }
      `}</style>
    </>
  );
};

export default CreateSeller;

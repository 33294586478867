import { Layout, Menu } from "antd";
// import { useState } from "react";
import { useHistory } from "react-router-dom";
// import ShopForBuyer from "../ShopForBuyer";
// import { useNavigate } from "react-router-dom";
// import { useMediaQuery } from "react-responsive";
// import HelpFeedback from "./HelpFeedback";

const { Sider } = Layout;
const { SubMenu } = Menu;

const SupplierSideNav = ({ currentPageKey }) => {
  // const navigate = useNavigate();
  // const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  const history = useHistory();

  // const [shopBuyerModalVisible, setShopBuyerModalVisible] = useState(false);

  // const [sideNavFeedbackVisible, setSideNavFeedbackVisible]=useState(false)

  return (
    <div>
      <Sider className="sider seller-sidenav" style={{ marginTop: 60 }}>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={[currentPageKey]}>
          <Menu.Item
            className="menu-item"
            key="1"
            onClick={() => history.push("/finance-dashboard")}
            icon={
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.1667 7.80998V3.64831C20.1667 2.35581 19.58 1.83331 18.1225 1.83331H14.4192C12.9617 1.83331 12.375 2.35581 12.375 3.64831V7.80081C12.375 9.10248 12.9617 9.61581 14.4192 9.61581H18.1225C19.58 9.62498 20.1667 9.10248 20.1667 7.80998Z"
                  fill="#800000"
                />
                <path
                  d="M20.1667 18.1225V14.4192C20.1667 12.9617 19.58 12.375 18.1225 12.375H14.4192C12.9617 12.375 12.375 12.9617 12.375 14.4192V18.1225C12.375 19.58 12.9617 20.1667 14.4192 20.1667H18.1225C19.58 20.1667 20.1667 19.58 20.1667 18.1225Z"
                  fill="black"
                />
                <path
                  d="M9.62492 7.80998V3.64831C9.62492 2.35581 9.03825 1.83331 7.58075 1.83331H3.87742C2.41992 1.83331 1.83325 2.35581 1.83325 3.64831V7.80081C1.83325 9.10248 2.41992 9.61581 3.87742 9.61581H7.58075C9.03825 9.62498 9.62492 9.10248 9.62492 7.80998Z"
                  fill="black"
                />
                <path
                  d="M9.62492 18.1225V14.4192C9.62492 12.9617 9.03825 12.375 7.58075 12.375H3.87742C2.41992 12.375 1.83325 12.9617 1.83325 14.4192V18.1225C1.83325 19.58 2.41992 20.1667 3.87742 20.1667H7.58075C9.03825 20.1667 9.62492 19.58 9.62492 18.1225Z"
                  fill="#800000"
                />
              </svg>
            }
          >
            Dashboard
          </Menu.Item>
          {/* <Menu.Item
            className="menu-item"
            onClick={() => history.push("/find-buyer")}
            key="14"
            icon={
              <svg
                width="22"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.9287 18.5L12.1162 13.3051L7.78271 14.8186L2.72083 13.1269L0 8.77782L0.895607 3.80634L4.98819 0.53724L10.3618 0.5L14.5026 3.7159L15.4719 8.67408L14.4006 10.4456L13.1705 9.78861L13.9868 8.44L13.2017 4.43143L9.85451 1.83264L5.51252 1.8619L2.20784 4.50325L1.48229 8.52246L3.68162 12.0363L7.77137 13.4035L12.5442 11.7384L18 17.6275L16.9287 18.5Z"
                  fill="#6B1214"
                />
              </svg>
            }
          >
            Find Customer
          </Menu.Item> */}

          <SubMenu
            className="menu-item"
            key="2"
            icon={
              <svg
                width="22"
                height="18"
                viewBox="0 0 25 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.84724 8.18493L2.11877 8.81153L1.72351 8.83601L0.97206 8.02094L0.99504 7.60239L1.55116 6.77509C1.41247 6.50839 1.30445 6.22487 1.22944 5.93065L0.294146 5.77155L0 5.49007V4.35926L0.294146 4.07778L1.23863 3.91624C1.31598 3.62214 1.42713 3.33933 1.56954 3.07425L1.01572 2.25673L0.992742 1.83574L1.74419 1.03535L2.13945 1.05983L2.91618 1.65216C3.17348 1.52197 3.4392 1.41154 3.71129 1.32173L3.85836 0.313299L4.12034 0H5.18432L5.44629 0.313299L5.60256 1.31928C5.8778 1.40211 6.1425 1.52048 6.39077 1.67174L7.15831 1.08186L7.55127 1.05738L8.30502 1.85776L8.27285 2.27876L7.71443 3.10606C7.85585 3.37224 7.96621 3.65582 8.04305 3.9505L8.97604 4.1096L9.27248 4.38863V5.52189L8.98064 5.80092L8.04305 5.96736C7.95078 6.25569 7.84022 6.537 7.71213 6.80936L8.27285 7.62442L8.29583 8.04297L7.54208 8.8458L7.13763 8.81153L6.3609 8.21675C6.12315 8.36397 5.87022 8.48142 5.60715 8.56677L5.41872 9.57275L5.15444 9.8885H4.09276L3.82849 9.57275L3.67682 8.56677C3.39215 8.46058 3.11492 8.33298 2.84724 8.18493Z"
                  fill="#6B1214"
                />
                <path
                  d="M5.31524 6.82158L4.02146 6.84606L3.01493 5.97714L2.76904 4.62359L3.3964 3.4169L4.60516 2.92493L5.83 3.37529L6.49642 4.55751L6.2919 5.9184L5.31524 6.82158Z"
                  fill="white"
                />
                <path
                  d="M14.1832 14.7447L13.5122 14.5758L16.0837 3.00086L15.5988 2.34489H9.35742V1.6106H15.7643L16.0354 1.75011L16.7272 2.68267L16.7938 2.99597L14.1832 14.7447Z"
                  fill="#010101"
                />
                <path
                  d="M2.92073 15.2513L2.57373 14.8401L3.02644 10.8847L3.71125 10.9728L3.3045 14.5097L3.93415 14.4999L3.94564 15.2342L2.92073 15.2513Z"
                  fill="#010101"
                />
                <path
                  d="M15.0996 14.4365L12.6223 14.4893L12.6361 15.2236L15.1134 15.1708L15.0996 14.4365Z"
                  fill="#010101"
                />
                <path
                  d="M23.18 15.0481L23.1708 14.3138H23.3156L24.2923 8.02095L22.4929 5.75932H15.9275V5.02502H22.6515L22.9157 5.15475L24.9219 7.67583L25 7.97199L23.9476 14.7348L23.612 15.0432L23.18 15.0481Z"
                  fill="#010101"
                />
                <path
                  d="M8.07989 18L7.85009 17.9706L6.24148 17.1066L6.08062 16.9255L5.36364 15.1558L5.34985 14.9111L5.8692 13.0656L6.00938 12.8648L7.52148 11.8075L7.75128 11.7487L9.54832 11.9715L9.75974 12.084L10.9984 13.4865L11.0926 13.7166L11.1983 15.638L11.1294 15.8828L10.0516 17.4273L9.85396 17.5668L8.07989 18ZM6.66432 16.5118L8.05462 17.2461L9.5713 16.8619L10.4905 15.5328L10.4009 13.8782L9.33001 12.6813L7.78345 12.483L6.48277 13.396L6.03466 14.9845L6.66432 16.5118Z"
                  fill="#010101"
                />
                <path
                  d="M18.8804 18L18.6506 17.9706L17.042 17.1066L16.8812 16.9255L16.1642 15.1558L16.1504 14.9111L16.6697 13.0656L16.8099 12.8648L18.3197 11.8075L18.5495 11.7487L20.3443 11.9715L20.5557 12.084L21.7966 13.4865L21.8908 13.7166L21.9942 15.638L21.9276 15.8828L20.8475 17.4273L20.6499 17.5668L18.8804 18ZM17.4511 16.5118L18.8437 17.2461L20.3581 16.8619L21.2773 15.5328L21.1853 13.8782L20.1168 12.6715L18.5817 12.483L17.281 13.4009L16.8352 14.9894L17.4511 16.5118Z"
                  fill="#010101"
                />
              </svg>
            }
            title="Suppliers"
          >
            <Menu.Item
              className="menu-item"
              onClick={() => history.push("/all-suppliers")}
              key="24"
            >
              All Suppliers
            </Menu.Item>

            <Menu.Item
              className="menu-item"
              onClick={() => history.push("/add-supplier")}
              key="26"
            >
              Add New Supplier
            </Menu.Item>
            <Menu.Item
              className="menu-item"
              onClick={() => history.push("/find-supplier")}
              key="27"
            >
              Find Supplier
            </Menu.Item>
          </SubMenu>

          <SubMenu
            className="menu-item"
            key="3"
            icon={
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.2167 2.375H13.0566L13.4525 6.33333C13.4525 6.33333 14.2441 7.125 15.4316 7.125C16.2843 7.125 16.8648 6.71686 17.1245 6.48668C17.2279 6.39494 17.2658 6.25489 17.2431 6.11848L16.6852 2.77191C16.6471 2.54287 16.4489 2.375 16.2167 2.375Z"
                  stroke="#6B1214"
                />
                <path
                  d="M13.0566 2.375L13.4525 6.33333C13.4525 6.33333 12.6608 7.125 11.4733 7.125C10.2858 7.125 9.49414 6.33333 9.49414 6.33333V2.375H13.0566Z"
                  stroke="#6B1214"
                />
                <path
                  d="M9.49545 2.375V6.33333C9.49545 6.33333 8.70378 7.125 7.51628 7.125C6.32878 7.125 5.53711 6.33333 5.53711 6.33333L5.93294 2.375H9.49545Z"
                  stroke="#6B1214"
                />
                <path
                  d="M5.93302 2.375H2.7729C2.5407 2.375 2.34254 2.54287 2.30436 2.77191L1.7466 6.11849C1.72387 6.25489 1.76173 6.39494 1.8652 6.48668C2.12481 6.71686 2.70535 7.125 3.558 7.125C4.7455 7.125 5.53718 6.33333 5.53718 6.33333L5.93302 2.375Z"
                  stroke="#6B1214"
                />
                <path
                  d="M2.375 7.125V15.0417C2.375 15.9161 3.08388 16.625 3.95833 16.625H15.0417C15.9161 16.625 16.625 15.9161 16.625 15.0417V7.125"
                  stroke="black"
                />
                <path
                  d="M11.7422 16.6253V11.8753C11.7422 11.0009 11.0333 10.292 10.1588 10.292H8.57551C7.70107 10.292 6.99219 11.0009 6.99219 11.8753V16.6253"
                  stroke="black"
                  stroke-miterlimit="16"
                />
              </svg>
            }
            title="Designer"
          >
            <Menu.Item
              className="menu-item"
              // onClick={() => history.push("/manage-orders/all")}
              key="34"
            >
              Dashboard
            </Menu.Item>
            <Menu.Item
              className="menu-item"
              // onClick={() => history.push("/manage-orders/all")}
              key="35"
            >
              Designer Metrics
            </Menu.Item>

            <Menu.Item
              className="menu-item"
              // onClick={() => history.push("/manage-orders/flagged")}
              key="36"
            >
              Find A Designer
            </Menu.Item>
            <Menu.Item
              className="menu-item"
              // onClick={() => history.push("/manage-orders/flagged")}
              key="37"
            >
              Designer Orders
            </Menu.Item>
          </SubMenu>

          <SubMenu
            className="menu-item"
            key="4"
            icon={
              <svg
                width="22"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 7.5H21L20 21H4L3 7.5Z"
                  stroke="black"
                  stroke-linejoin="round"
                />
                <path
                  d="M8 9.5V3H16V9.5"
                  stroke="#800000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path d="M8 17H16" stroke="#800000" stroke-linecap="round" />
              </svg>
            }
            title="Buyers"
          >
            <Menu.Item
              className="menu-item"
              // onClick={() => history.push("/manage-orders/all")}
              key="44"
            >
              Dashboard
            </Menu.Item>
            <Menu.Item
              className="menu-item"
              // onClick={() => history.push("/manage-orders/all")}
              key="45"
            >
              Buyer Metrics
            </Menu.Item>

            <Menu.Item
              className="menu-item"
              // onClick={() => history.push("/manage-orders/flagged")}
              key="46"
            >
              Find A Buyer
            </Menu.Item>
            <Menu.Item
              className="menu-item"
              // onClick={() => history.push("/manage-orders/flagged")}
              key="47"
            >
              Buyer Orders
            </Menu.Item>
            <Menu.Item
              className="menu-item"
              // onClick={() => history.push("/manage-orders/flagged")}
              key="48"
            >
              Process A Refund
            </Menu.Item>
          </SubMenu>

          <SubMenu
            className="menu-item"
            key="66"
            icon={
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.86523 11.3443C6.86523 12.3655 7.64898 13.1889 8.62273 13.1889H10.6098C11.4569 13.1889 12.1457 12.4685 12.1457 11.5818C12.1457 10.616 11.7261 10.2755 11.1007 10.0539L7.91023 8.94555C7.28482 8.72388 6.86523 8.38346 6.86523 7.41763C6.86523 6.53096 7.55398 5.81055 8.40107 5.81055H10.3882C11.3619 5.81055 12.1457 6.63388 12.1457 7.65513"
                  stroke="#6B1214"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.5 4.75V14.25"
                  stroke="#6B1214"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.50065 17.4163C13.8729 17.4163 17.4173 13.8719 17.4173 9.49967C17.4173 5.12742 13.8729 1.58301 9.50065 1.58301C5.1284 1.58301 1.58398 5.12742 1.58398 9.49967C1.58398 13.8719 5.1284 17.4163 9.50065 17.4163Z"
                  stroke="black"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
            title="Currency Tables "
          >
            <SubMenu
              className="menu-item currency-map-menu"
              key="67"
              title="Country/Currency Map"
            >
              <Menu.Item
                className="menu-item"
                onClick={() => history.push("/currency-map/1")}
                key="46"
              >
                Nigerian Naira (NGN)
              </Menu.Item>
              <Menu.Item
                className="menu-item"
                onClick={() => history.push("/currency-map/276")}
                key="47"
              >
                United States Dollar (USD)
              </Menu.Item>
              <Menu.Item
                className="menu-item"
                onClick={() => history.push("/currency-map/144")}
                key="48"
              >
                Great British Pound (GBP)
              </Menu.Item>

              <Menu.Item
                className="menu-item"
                onClick={() => history.push("/currency-map/194")}
                key="480"
              >
                Euros (EUR)
              </Menu.Item>
            </SubMenu>
            <Menu.Item
              className="menu-item"
              onClick={() => history.push("/rate-conversion")}
              key="45"
            >
              Rate Conversions
            </Menu.Item>
          </SubMenu>
          <Menu.Item
            className="menu-item"
            key="1"
            onClick={() => history.push("/finance-dashboard")}
            icon={
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.12565 1.58301H11.8757C15.834 1.58301 17.4173 3.16634 17.4173 7.12467V11.8747C17.4173 15.833 15.834 17.4163 11.8757 17.4163H7.12565C3.16732 17.4163 1.58398 15.833 1.58398 11.8747V7.12467C1.58398 3.16634 3.16732 1.58301 7.12565 1.58301Z"
                  stroke="black"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.78516 12.0886L11.9626 6.91113"
                  stroke="#6B1214"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.10852 8.20921C7.64631 8.20921 8.08226 7.77326 8.08226 7.23548C8.08226 6.69769 7.64631 6.26172 7.10852 6.26172C6.57074 6.26172 6.13477 6.69769 6.13477 7.23548C6.13477 7.77326 6.57074 8.20921 7.10852 8.20921Z"
                  stroke="#6B1214"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.2862 12.7375C12.824 12.7375 13.26 12.3016 13.26 11.7638C13.26 11.226 12.824 10.79 12.2862 10.79C11.7484 10.79 11.3125 11.226 11.3125 11.7638C11.3125 12.3016 11.7484 12.7375 12.2862 12.7375Z"
                  stroke="#6B1214"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
          >
            Taxes
          </Menu.Item>
          <Menu.Item
            className="menu-item"
            key="1"
            onClick={() => history.push("/finance-dashboard")}
            icon={
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.90625 11.875H10.0938V14.25H8.90625V11.875ZM11.875 10.6875H13.0625V14.25H11.875V10.6875ZM5.9375 8.3125H7.125V14.25H5.9375V8.3125Z"
                  fill="#6B1214"
                />
                <path
                  d="M14.8438 2.96875H13.0625V2.375C13.0625 2.06006 12.9374 1.75801 12.7147 1.53531C12.492 1.31261 12.1899 1.1875 11.875 1.1875H7.125C6.81006 1.1875 6.50801 1.31261 6.28531 1.53531C6.06261 1.75801 5.9375 2.06006 5.9375 2.375V2.96875H4.15625C3.84131 2.96875 3.53926 3.09386 3.31656 3.31656C3.09386 3.53926 2.96875 3.84131 2.96875 4.15625V16.625C2.96875 16.9399 3.09386 17.242 3.31656 17.4647C3.53926 17.6874 3.84131 17.8125 4.15625 17.8125H14.8438C15.1587 17.8125 15.4607 17.6874 15.6834 17.4647C15.9061 17.242 16.0312 16.9399 16.0312 16.625V4.15625C16.0312 3.84131 15.9061 3.53926 15.6834 3.31656C15.4607 3.09386 15.1587 2.96875 14.8438 2.96875ZM7.125 2.375H11.875V4.75H7.125V2.375ZM14.8438 16.625H4.15625V4.15625H5.9375V5.9375H13.0625V4.15625H14.8438V16.625Z"
                  fill="black"
                />
              </svg>
            }
          >
            Reports
          </Menu.Item>
        </Menu>
      </Sider>

      {/* <ShopForBuyer
        shopBuyerModalVisible={shopBuyerModalVisible}
        setShopBuyerModalVisible={setShopBuyerModalVisible}
      /> */}

      {/* <HelpFeedback sideNavFeedbackVisible={sideNavFeedbackVisible} setSideNavFeedbackVisible={setSideNavFeedbackVisible} /> */}

      <style>
        {`
            .seller-sidenav{
              font-family: 'DomaineSansText-Light';
            }

            .ant-menu-dark.ant-menu-inline, 
            .ant-menu-dark.ant-menu-vertical, 
            .ant-menu-dark.ant-menu-vertical-left, 
            .ant-menu-dark.ant-menu-vertical-right{
                background: white;
            }

            .ant-menu-item .ant-menu-item-icon + span, 
            .ant-menu-submenu-title .ant-menu-item-icon + span, 
            .ant-menu-item .anticon + span, 
            .ant-menu-submenu-title .anticon + span, .ant-menu-dark .ant-menu-item-selected 
            .ant-menu-item-icon + span, .ant-menu-dark
            .ant-menu-item-selected .anticon + span{
                color: black;
            }
            .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
                background-color: rgba(0, 0, 0, 0.04);
            }
            .ant-layout-sider{
                background:white;
                border-right: 1px solid #e4e4e4;
                height:100%;
                position:fixed

            }

            .ant-menu-dark.ant-menu-inline .ant-menu-item,
            .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
              align-items: center; 
              color:#000;
              display: flex;
              padding: 16px;
              width: 100%;
            }
        
            .ant-menu-dark .ant-menu-sub,
            .ant-menu.ant-menu-dark .ant-menu-sub {
              background: #fff;
              color: #fff;
            }
            .ant-menu.ant-menu-dark .sub-item {
              background: #252525;
              color: #fff;
              margin: 1px;
            }
          
           .ant-menu-submenu-arrow::before,.ant-menu-submenu-arrow::after {
              background: #000 !important;
              margin:20%;
            }
            .ant-menu-dark.ant-menu-inline, .ant-menu-dark.ant-menu-vertical, .ant-menu-dark.ant-menu-vertical-left, .ant-menu-dark.ant-menu-vertical-right{
              margin-top:96px
            }
            .currency-map-menu .ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{
              height: 48px;
              line-height: 22px;
              white-space: normal;
            }

            @media (max-width: 640px) {
              .ant-layout-sider{
                border-right: 0

              }
            }
            `}
      </style>
    </div>
  );
};

export default SupplierSideNav;

import { useState, useEffect, useRef } from "react";
import { Rate } from "antd";
import ListingView from "./ListingView";
import { useSelector, useDispatch } from "react-redux";
import { countryInfo } from "../../assets/constants";
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { bindActionCreators } from "redux";
import { brandActionCreators } from "../../redux/seller";

const StorePreview = () => {
  const dispatch = useDispatch();
  const [curPage, setCurPage] = useState(1);

  const {
    brand,
    brandDetails: details,
    brandProducts: listings,
  } = useSelector((state) => state.brands);

  const { info } = useSelector((state) => state.user);

  const { fetchBrandListings } = bindActionCreators(
    brandActionCreators,
    dispatch
  );

  let statusColor;
  let color;
  if (brand?.status === "activated") {
    color = "#10554A";
    statusColor = "rgba(30, 174, 152, 0.4)";
  }
  if (brand?.status === "blocked") {
    color = "#D00202";
    statusColor = "rgba(208, 2, 2, 0.2)";
  }
  if (brand?.status === "vacation") {
    color = "#DBA820";
    statusColor = "rgba(253, 202, 64, 0.2)";
  }
  if (brand?.status === "deactivated") {
    color = "#F6BBBB";
    statusColor = "#800000";
  }

  const country = countryInfo.find(
    (item) => item.code === details?.storeCountry
  );

  const getPaginatedData = (page) => {
     fetchBrandListings(info.token, brand?.sellerId, page);

  };

    const scrollToRef = (ref) =>
      window.scrollTo({
        top: ref?.current?.offsetTop - 120,
        behavior: "smooth",
      });
    const topOfPageRef = useRef(null);

  useEffect(() => {
    setCurPage(listings?.meta_data?.current_page);
  }, [listings?.meta_data?.current_page]);

  return (
    <div>
      <div>
        <div className="store-header-section">
          <div
            className="store-header-banner-section"
            style={{ position: "relative" }}
          >
            <div
              className="brand-img"
              style={{
                backgroundImage: `url(${details?.logoUrl})`,
              }}
            ></div>
            <div className="store-preview-status">{brand?.status}</div>
          </div>
          <div className="store-header-banner-text-section">
            <div className="store-header-banner-text-header">
              <div className="store-header-banner-text-header-store-name">
                {brand?.store_name}
              </div>
              <div>
                <Rate
                  allowHalf
                  disabled
                  value={brand?.rating}
                  style={{ color: "#800000", fontSize: 14 }}
                />
              </div>
              <div>
                {details?.brandCategory?.map((category) => (
                  <span style={{ marginRight: 10 }}>{category}</span>
                ))}
              </div>
              <div className="store-header-banner-text-header-flex-section">
                <img src={country?.flag} alt="NG" />
                <div>{country?.name}</div>
              </div>
              <div className="store-header-banner-text-header-flex-section">
                <svg
                  width="21"
                  height="18"
                  viewBox="0 0 21 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.687 17.9749H9.32522L0 8.64227V2.86113L2.83348 0.0253906H8.61L9.74826 1.16761L8.67391 2.24281L7.98 1.54834H3.46348L1.52174 3.49163V8.01176L9.95522 16.452H11.057L19.4783 8.0209V3.50077L17.5335 1.55444L13.023 1.55748L5.96217 8.64531L4.88478 7.57011L12.39 0.0375743L18.1635 0.0314824L21 2.87332V8.6514L11.687 17.9749Z"
                    fill="white"
                  />
                </svg>
                <div>Follow Store</div>
              </div>
            </div>
            <div className="store-header-banner-text-details">
              {brand?.description}
            </div>
          </div>
        </div>

        <div>
          <div
            style={{
              fontSize: "18px",
              padding: "40px 24px 0",
              fontFamily: "DomaineSansText-Light",
            }}
          >
            {listings?.meta_data?.total} Items
          </div>
          <div className="dashboard-body-content-listings-container">
            {listings?.data?.length > 0 ? (
              listings?.data?.map((listing) => (
                <ListingView key={listing.id} product={listing} />
              ))
            ) : (
              <div className="no-listings-to-show">
                <div>
                  <div>Designer currently has 0 listings</div>
                </div>
              </div>
            )}
           
          </div>
          <div className="pagination-container">
              <div className="pagination-top-icon-container">
                <svg
                  width="33"
                  height="36"
                  viewBox="0 0 33 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  // onClick={() => scrollToRef(topOfPageRef)}
                  style={{ cursor: "pointer" }}
                >
                  <circle
                    cx="16.0928"
                    cy="18.3921"
                    r="15.8054"
                    stroke="#D9D9D9"
                    stroke-width="0.574743"
                  />
                  <path
                    d="M22.9896 25.289L16.0927 18.3921L9.1958 25.289"
                    stroke="black"
                    stroke-width="0.574743"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.04639 13.7939H24.1392"
                    stroke="black"
                    stroke-width="0.574743"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="rows-box">
                <p
                  className="txt-next"
                  style={{
                    color:
                      listings?.meta_data?.current_page !== 1
                        ? "black"
                        : "#d9d9d9",
                    justifyContent: "flex-start",
                    pointerEvents:
                      listings?.meta_data?.current_page !== 1 ? "" : "none",
                  }}
                >
                  <DoubleLeftOutlined
                    onClick={() => {
                      getPaginatedData(1);
                       scrollToRef(topOfPageRef);
                    }}
                  />
                  <LeftOutlined
                    onClick={() => {
                      getPaginatedData(curPage - 1);
                       scrollToRef(topOfPageRef);
                    }}
                  />
                  <span
                    onClick={() => {
                      getPaginatedData(curPage - 1);
                       scrollToRef(topOfPageRef);
                    }}
                  >
                    Previous
                  </span>
                </p>

                <p className="num-text">
                  Page {listings?.meta_data?.current_page} of{" "}
                  {listings?.meta_data?.last_page}
                </p>

                <p
                  className="txt-next"
                  style={{
                    color:
                      listings?.meta_data?.current_page !==
                      listings?.meta_data?.last_page
                        ? "black"
                        : "#d9d9d9",
                    justifyContent: "flex-end",
                  }}
                >
                  <span
                    onClick={() => {
                      getPaginatedData(curPage + 1);
                       scrollToRef(topOfPageRef);
                    }}
                  >
                    Next
                  </span>
                  <RightOutlined
                    onClick={() => {
                      getPaginatedData(curPage + 1);
                       scrollToRef(topOfPageRef);
                    }}
                  />
                  <DoubleRightOutlined
                    onClick={() => {
                      getPaginatedData(listings?.meta_data?.last_page);
                       scrollToRef(topOfPageRef);
                    }}
                  />
                </p>
              </div>
            </div>
        </div>
      </div>

      <style>
        {`
                    .store-header-banner-section {
                    background: url(https://res.cloudinary.com/losode-ng/image/upload/v1642599051/website-pictures-optimized/hom-car_m61oic_awnuxf.jpg);
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: calc(100vh - 240px);
                    position: relative;
                    }
                    .store-header-banner-text-section {
                    background: black;
                    color: white;
                    display: flex;
                    padding: 2em;
                    font-size: 16px;
                    font-family: "DomaineSansText-Light";
                    padding-right: calc(2em + 16px);
                    }
                    .store-header-banner-text-header {
                    min-width: 25%;
                    padding-right: 24px;
                    margin-right: 24px;
                    border-right: 1px solid white;
                    }
                    .store-header-banner-text-header-store-name {
                    font-size: 28px;
                    font-family: "DomaineSansText-Regular";
                    text-decoration: underline;
                    }
                    .store-header-banner-text-header-flex-section {
                    display: flex;
                    gap: 8px;
                    padding: 8px 0;
                    align-items: center;
                    }
                    .store-header-banner-text-header-flex-section img {
                    width: 20px;
                    height: 20px;
                    }


                    .container {
                    align-items: center;
                    background: #f9f9f9;
                    display: flex;
                    height: 40px;
                    justify-content: space-between;
                    padding: 0 24px;
                    }
                    .vText {
                    font: normal 400 14px DomaineSansText-Regular;
                    margin-top: 15px;
                    }
                    .code {
                    color: #000000;
                    font-weight: 700;
                    font-style: normal;
                    text-decoration: underline;
                    }
            
                    .discount-sell-link {
                    text-decoration: underline;
                    font: normal normal normal 14px DomaineSansText-Regular;
                    letter-spacing: 0px;
                    color: #000000;
                    }
            
                    @media(max-width:640px){
                    .discount-sell-link{
                        display: none;
                    }
                    .container{
                        padding: 8px 16px;
                        justify-content: center;
                        height:auto;
                    }
                    .vText{
                        font-size: 10px;
                        text-align: center;
                        margin: 0;
                    }
                    }

                    .homepage-header-layout {
                    align-items: center;
                    background: #fff;
                    display: flex;
                    height: 96px;
                    justify-content: space-between;
                    padding: 0;
                    }
                    .homepage-header-layout
                    .ant-menu-horizontal:not(.ant-menu-dark)
                    > .ant-menu-item:hover::after,
                    .homepage-header-layout .ant-menu-horizontal > .ant-menu-item::after,
                    .ant-menu-horizontal > .ant-menu-submenu::after {
                    border-bottom: 0;
                    }
                    .user-account-dropdown {
                    }
                    .account-menu {
                    }
                    .account-menu .ant-menu-inline,
                    .ant-menu-vertical,
                    .ant-menu-vertical-left {
                    border: 0px;
                    }
                    .menu-line {
                    display: block;
                    border: 0.3px solid #92959e;
                    opacity: 0.3;
                    margin-bottom: 24px;
                    }

                    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
                    background-color: transparent;
                    }
                    .ant-menu-item:active,
                    .ant-menu-submenu-title:active {
                    background: transparent;
                    }
                    .btn-menu {
                    color: #677189;
                    font: normal normal 16px/146.16% DomaineSansText-Light;
                    width: 100%;
                    }
                    .btn-menu:hover {
                    color: #be002b !important;
                    font-weight: 700;
                    }
                    .ant-menu-horizontal > .ant-menu-item::after,
                    .ant-menu-horizontal > . ant-menu-submenu::after {
                    display: none;
                    }
                    .icon-cur {
                    height: 18px;
                    width: 18px;
                    }
                    .txt-cur {
                    font: normal normal 12px/22px DomaineSansText-Light;
                    margin-left: 12px;
                    }
                    .nav-item {
                    text-decoration: none;
                    font-size: 15px;
                    font-family: DomaineSansText-Light;
                    }
                    .nav-item-active {
                    text-decoration: none;
                    font-size: 16px;
                    font-family: DomaineSansText-Black;
                    }
                    .menu-icon {
                    border: 0px;
                    cursor: pointer;
                    margin: 0px;
                    padding: 0px 10px;
                    }
                    .menu-items-list {
                    flex: 1;
                    position: absolute;
                    z-index: 201;
                    width: 35vw;
                    }
                    .account-menu-items {
                    right: 0;
                    position: absolute;
                    z-index: 201;
                    align-items:center
                    }
                    .menu-logo-container {
                    place-items: left;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    z-index: 200;
                    }
                    .auth-span {
                    color: #000;
                    text-decoration: underline;
                    font-size: 14px;
                    font-family: DomaineSansText-Light;
                    }
                    .auth-span:hover {
                    color: #be002b;
                    text-decoration: none;
                    }

                    .ant-popover-placement-bottomRight .ant-popover-arrow {
                    display: none;
                    }
                    .ant-popover-title {
                    font-family: DomaineSansText-Regular;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 20px;
                    padding: 15px 25px;
                    }
                    .ant-popover {
                    left: 75% !important;
                    padding-top: 10px;
                    width: 25vw;
                    }

                    .header-cart-page-no-item-supercontainer {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    padding: 16px 0;
                    font-family: "DomaineSansText-Light";
                    }
                    .header-cart-page-no-item-container {
                    background: white;
                    padding: 40px;
                    font-size: 14px;
                    text-align: center;
                    }
                    .header-cart-page-no-item-bag-icon {
                    height: 24px;
                    }
                    .header-cart-page-no-item-title {
                    font-size: 18px;
                    margin-top: 14px;
                    }
                    .header-cart-page-no-item-shop-link {
                    text-decoration: underline;
                    cursor: pointer;
                    }

                    .cart-item-img {
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    height: 138px;
                    width: 96px;
                    }
                    .layout {
                        align-items: center;
                        background: #fff;
                        display: flex;
                        height: 96px;
                        justify-content: space-between;
                        padding: 0 40px !important;
                    }
                    
                    .logo {
                        cursor: pointer;
                        width:248px;
                    }
                    
                    .subText {
                        font: normal normal 15px DomaineSansText-Regular;
                        letter-spacing: 1.3px;
                        color: #000000;
                        text-align: center;
                    }
                    .links {
                        display: flex;
                        justify-content: space-evenly;
                        margin-top: -0.5em;
                        align-items:center
                    }
                    .ant-menu-item a::before{
                        display:none;
                    }

                    .ant-layout-header {
                        padding: 0px;
                    }
                    .sale {
                    background: #ff0000 !important;
                    color: #fff !important;
                    font-size: 18px;
                    font-family: DomaineSansText-Light;
                    text-align: center;
                    text-decoration: none;
                    width: 130px;
                    }
                    .item {
                    background: #000 !important;
                    color: #fff !important;
                    font-size: 16px;
                    font-family: DomaineSansText-Light;
                    text-decoration: none;
                    padding: 0 16px !important;
                    }
                    .item:hover {
                    background: #fff !important;
                    color: #000 !important;
                    }
                    .active {
                    background: #fff !important;
                    color: #000 !important;
                    font-size: 18px;
                    font-family: DomaineSansText-Light;
                    text-decoration: none;
                    }
            
                    .search-container .ant-input-group {
                    margin-top: 12px;
                    }
            
                    .item-search {
                    background: transparent !important;
                    font-size: 16px;
                    font-family: DomaineSansText-Light;
                    // margin-left: 10rem;
                    text-decoration: none;
                    width: 30%;
                    }
                    .item-search:hover {
                    background: transparent !important;
                    color: #000;
                    }
                    .search-container
                    .ant-input-search
                    .ant-input-group
                    .ant-input-affix-wrapper:not(:last-child) {
                    height: 40px;
                    background: #2f2f2f;
                    border: 0px;
                    }
                    .search-container
                    .ant-input-search
                    .ant-input-group
                    .ant-input-affix-wrapper:not(:last-child):hover {
                    background: ##2f2f2f;
                    border: 0px;
                    }
                    .search-container
                    .ant-input-search
                    > .ant-input-group
                    > .ant-input-group-addon:last-child
                    .ant-input-search-button:not(.ant-btn-primary) {
                    background-color: #2f2f2f;
                    border: 0px;
                    color: #fff;
                    height: 40px;
                    width: 60px;
                    }
                    .search-container .txt-search {
                    box-shadow: 0px 3px 10px rgb(0 0 0 / 0.2);
                    color: #fff;
                    cursor: pointer;
                    font: normal normal 14px/146.16% DomaineSansText-Regular;
                    margin: 0px;
                    margin-bottom: 10px;
                    padding: 5px;
                    position: absolute;
                    transition: box-shadow 0.5s;
                    }
                    .search-container .txt-search:hover {
                    box-shadow: 0px 3px 10px 5px rgb(0 0 0 / 0.2);
                    }
                    .ant-input-affix-wrapper > input.ant-input {
                    background: rgb(249, 249, 249, 0.01);
                    color: #fff;
                    }

                    .dashboard-body-content-listings-container {
                        display: flex;
                        flex-wrap:wrap;
                        padding:24px 24px;
                        row-gap: 20px;

                    }
                    .manage-listings-body-content-listing-item-container{
                        width:20% !important
                    }

                    .store-preview-status{
                        background: ${statusColor};
                        text-transform: capitalize;                  
                        color: ${color};
                        position: absolute;
                        right: 40px;
                        top: 40px;
                        font-size: 16px;
                        font-family: 'DomaineSansText-Light';
                        padding: 16px 32px;
                        border: 0;
                    }
                    .brand-img {
                        background-color: #fff;
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                        height: 200px;
                        position: absolute;
                        width: 200px;
                        border: 1px solid #bdbdbd;
                        bottom: 24px;
                        right: calc(2em + 16px);
                    }

                    .pagination-container {
                      display: flex;
                      justify-content: center;
                      margin: 60px auto;
                      width: 80%;
                      font-family: "DomaineSansText-Light";
                      font-size: 14px;
                      flex-direction: column;
                      gap: 40px;
                    }
                    .pagination-top-icon-container {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      border-bottom: 1px solid #d9d9d9;
                      padding-bottom: 16px;
                    }
                    .rows-box {
                      display: flex;
                      justify-content: space-between;
                      width: 100%;
                    }
                    .num-text {
                      color: #000000;
                      flex-basis: 33%;
                      text-align: center;
                    }
                    .txt-next {
                      cursor: pointer;
                      display: flex;
                      align-items: center;
                      gap: 24px;
                      flex-basis: 33%;
                    }
                    .btn-num {
                      background: rgba(0, 0, 0, 0.1);
                      height: 30px;
                      width: 80px;
                    }
                    
                `}
      </style>
    </div>
  );
};

export default StorePreview;

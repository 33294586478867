import { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Steps, Radio, Input, Modal, message } from "antd";
import { ordersActionCreators } from "../../redux/orders";
import Dashboard from "../seller-mgmt/Dashboard";
import formatter from "../../utils/formatter";
import standardizeColor from "../../utils/standardizeColor";

const { Step } = Steps;

const OrderDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [rejectedModalVisible, setRejectedModalVisible] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [rejectionConfirm, setRejectionConfirm] = useState("");

  const cancelRejectionModal = () => {
    setRejectedModalVisible(false);
    setRejectionConfirm("");
    setRejectionReason("");
  };

  const id = window.location.pathname.split("/")[2];

  const { info } = useSelector((state) => state.user);
  const { order } = useSelector((state) => state.orders);

  const buyer = order?.users?.buyer;
  const seller = order?.users?.seller;

  const { fetchOrder, markOrderSent } = bindActionCreators(
    ordersActionCreators,
    dispatch
  );

  const isTabletOrMobile = false;

  // const rejectOrder = async (id) => {
  //   const res = await declineOrder(info.token, {
  //     sale_id: id,
  //     reject_reason: rejectionReason,
  //   });
  //   if (res.status === "ok") {
  //     message.success(res.message);
  //     setRejectedModalVisible(false);
  //   }
  // };

  const orderSent = async (id) => {
    const res = await markOrderSent(info.token, {
      sale_id: id,
    });
    if (res.status === "ok") {
      message.success(res.message);
    }
  };

  useEffect(() => {
    fetchOrder(info.token, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dashboard
      headerTitle="The Designer Management Portal"
      // //headerSubtitle="Get inisights on all users using your app"
      dashboardBgColor="#f7f7f7"
      noSideNav
    >
      <div className="order-details-container">
        {/* <div className='order-details-sidenav-container'>
            
        </div> */}
        <div className="manage-listing-all-content">
          {/* {!isTabletOrMobile && <SellerSideNav currentPageKey="2" />} */}
          <div className="order-details-coverall-container">
            <div className="order-details-main-content">
              <div style={{ background: "white" }}>
                {/* <div className="order-details-adspace"></div> */}
              </div>
              {/* <div className="order-details-body-content-header">
              <div className="order-details-body-content-header-container">
                <div className="order-details-body-content-header-title">
                  Order Details
                </div>
                <div className="order-details-body-content-header-subtitle">
                    In your Store settings, you can edit or change your store
                    details and manage general characteristics for your Store.
                    Remember to join the Losode Seller Community here and take
                    advantage of our affordable photoshoot packages
                </div>
              </div>
            </div> */}
              <div style={{ padding: !isTabletOrMobile ? "16px 40px" : "0px" }}>
                <div
                  style={{ cursor: "pointer", marginBottom: "16px" }}
                  onClick={() => history.goBack()}
                >
                  <svg
                    width="28"
                    height="10"
                    viewBox="0 0 28 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.94997 5.35647C0.994502 5.47131 1.0613 5.57678 1.14684 5.66583L4.89684 9.41583C5.07263 9.59278 5.31285 9.69356 5.56248 9.69356C5.81211 9.69356 6.05232 9.59278 6.22812 9.41583C6.40508 9.24004 6.50586 8.99982 6.50586 8.75019C6.50586 8.50056 6.40508 8.26035 6.22812 8.08455L4.07187 5.93769H26.6562C27.1742 5.93769 27.5937 5.51817 27.5937 5.00019C27.5937 4.48221 27.1742 4.06269 26.6562 4.06269H4.07187L6.22812 1.91583C6.5961 1.54785 6.5961 0.952559 6.22812 0.584579C5.86014 0.216599 5.26485 0.216599 4.89687 0.584579L1.14687 4.33458C1.06133 4.42364 0.994532 4.52911 0.95 4.64394C0.901952 4.75644 0.876173 4.87714 0.875 5.00019C0.876172 5.12324 0.901953 5.24394 0.95 5.35644L0.94997 5.35647Z"
                      fill="black"
                    />
                  </svg>

                  <span
                    style={{
                      fontFamily: "DomaineSansText-Light",
                      marginLeft: "8px",
                      cursor: "pointer",
                    }}
                  >
                    Back
                  </span>
                </div>

                <div className="section-in-order-details order-info-section">
                  <div className="order-details-section-title">
                    Designer Information
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        <td className="order-info-label">Designer Name</td>
                        {/* <td>{order.sold_on}</td> */}
                        <td>{seller?.storeName}</td>
                      </tr>
                      <tr>
                        <td className="order-info-label">Designer Id</td>
                        {/* <td>{order.OrderNumber}</td> */}
                        <td>{seller?.brandId}</td>
                      </tr>

                      <tr>
                        <td className="order-info-label">
                          Number Of Transactions completed
                        </td>
                        <td>{seller?.transactionsCompleted}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div
                  className="section-in-order-details delivery-address-section"
                  style={{ marginTop: "0" }}
                >
                  <div className="order-details-section-title">
                    Designer Address
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        lineHeight: "2",
                        width: !isTabletOrMobile ? "150px" : "100%",
                      }}
                    >
                      {seller?.address}
                    </div>
                    {/* <Button
                      style={{
                        background: "black",
                        color: "white",
                        height: "48px",
                        padding: "0 50px",
                      }}
                    >
                      Edit Brand Address
                    </Button> */}
                  </div>
                </div>

                <div className="section-in-order-details order-info-section">
                  <div className="order-details-section-title">
                    Order Information
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        <td className="order-info-label">Time Placed</td>
                        {/* <td>{order.sold_on}</td> */}
                        <td>{order?.sold_on}</td>
                      </tr>
                      <tr>
                        <td className="order-info-label">Order Number</td>
                        {/* <td>{order.OrderNumber}</td> */}
                        <td>{order?.OrderNumber}</td>
                      </tr>
                      <tr>
                        <td className="order-info-label">Total</td>
                        <td>{formatter(order?.totalAmount)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="section-in-order-details delivery-info-section">
                  <div className="order-details-section-title">
                    Delivery Information
                  </div>
                  <div>
                    <div>
                      Estimated delivery time shown to buyer -{" "}
                      {order?.EstimatedDeliveryDate}
                    </div>
                    <Steps
                      current={order?.status === "Item Sent" ? 1 : 0}
                      labelPlacement="vertical"
                      style={{ marginTop: "20px" }}
                    >
                      <Step
                        title="Paid"
                        // description={order.sold_on}
                      />
                      <Step title="Item Sent" description="" />
                      <Step title="Delivered" description="" />
                    </Steps>
                  </div>
                </div>

                <div className="section-in-order-details delivery-address-section">
                  <div className="order-details-section-title">
                    Delivery Address
                  </div>
                  <div>
                    <div style={{ width: !isTabletOrMobile ? "80%" : "100%" }}>
                      Ensure your buyer’s address is entered correctly on your
                      shipment label. Include state and country details So that
                      address is matched with ease
                    </div>
                    <div
                      style={{
                        fontWeight: "bold",
                        marginTop: "20px",
                        lineHeight: "2",
                        width: !isTabletOrMobile ? "150px" : "100%",
                      }}
                    >
                      {buyer?.address}
                    </div>
                  </div>
                </div>

                <div className="section-in-order-details-row payment-info-section">
                  <div className="order-details-section-title">
                    <div>Payment Information</div>
                    <img
                      alt="placeholder"
                      src={order?.productImage}
                      className="order-details-payment-image"
                    ></img>
                    <div
                      style={{
                        width: "200px",
                        fontWeight: "normal",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {order?.productName}
                      {order?.productColor && (
                        <div>
                          <div
                            className="product-color-section-box"
                            style={{
                              background: `${standardizeColor(
                                order?.productColor
                              )}`,
                            }}
                          ></div>{" "}
                          {order?.productColor}
                        </div>
                      )}
                      {order?.productColor && <p>{order?.productSize}</p>}
                    </div>
                  </div>
                  <div>
                    <div className="order-details-payment-table-container">
                      <table className="order-details-payment-table">
                        <tbody>
                          <tr>
                            <td className="order-info-label">Item Price</td>
                            <td className="order-info-number">
                              {formatter(order?.itemPrice)}
                            </td>
                          </tr>
                          <tr>
                            <td className="order-info-label">Delivery:</td>
                            <td className="order-info-number">
                              {formatter(order?.deliveryCost)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="order-details-payment-table-divider"></div>
                      <table className="order-details-payment-table">
                        <tbody>
                          <tr>
                            <td className="order-info-label">
                              Total Paid By Buyer
                            </td>
                            <td className="order-info-number">
                              {formatter(order?.totalAmount)}
                            </td>
                          </tr>
                          <tr>
                            <td className="order-info-label">Quantity</td>
                            <td className="order-info-number">
                              {order?.quantity} item(s)
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="section-in-order-details-row action-section">
                  {order?.status === "Buyer has paid" && (
                    <div
                      className="action-section-link"
                      onClick={() => orderSent(order.sale_id)}
                    >
                      Confirm Item has been sent
                    </div>
                  )}
                  <div className="action-section-divider"></div>
                  <div className="action-section-link">View listing</div>
                  <div className="action-section-divider"></div>
                  {/* {order?.status === "Buyer has paid" && (
                    <div
                      className="action-section-link"
                      onClick={() => setRejectedModalVisible(true)}
                    >
                      Reject this order
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          centered
          visible={rejectedModalVisible}
          footer={null}
          onCancel={() => cancelRejectionModal()}
          closable={false}
          width={820}
          maskClosable={false}
          style={{ fontFamily: "DomaineSansText-Light" }}
        >
          <div style={{ padding: "0 20px", fontSize: "14px" }}>
            <div
              style={{
                fontWeight: "bold",
                fontSize: !isTabletOrMobile ? "24px" : "18px",
                textAlign: "center",
                marginBottom: "4px",
              }}
            >
              Why are you rejecting this order?
            </div>
            <div
              style={{
                fontSize: !isTabletOrMobile ? "14px" : "12px",
                textAlign: "center",
              }}
            >
              It is important that your store is kept up to date, multiple order
              rejections may lead to us closing your store
            </div>
            {rejectionConfirm === "" && (
              <div>
                <Radio.Group
                  onChange={(e) => setRejectionReason(e.target.value)}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "8px",
                    margin: "30px auto 50px",
                  }}
                >
                  <Radio value="out of stock">
                    The item is out of stock and there are no alternatives
                  </Radio>
                  <Radio value="longer than planned">
                    The item is available but will take longer than planned
                  </Radio>
                  <Radio value="alternate colors">
                    The item is available but in a different colour
                  </Radio>
                  <Radio value="reason not here">My reason is not here</Radio>
                </Radio.Group>
                {rejectionReason === "reason not here" && (
                  <Input.TextArea
                    style={{ minHeight: "100px" }}
                    className="no-reason-textbox"
                    placeholder="Please enter your reason for rejecting this order"
                  />
                )}
              </div>
            )}

            {rejectionConfirm === "longer than planned" && (
              <div
                style={{
                  background: "black",
                  color: "white",
                  padding: "20px",
                  margin: "30px auto 50px",
                  fontSize: "14px",
                }}
              >
                We can manage the buyer’s expectations on your behalf by letting
                them know that their order will take longer than planned.
                Instead of rejecting the order, send a message to our seller
                team at{" "}
                <a
                  href="mailto:designers@losode.com"
                  style={{ color: "white", textDecoration: "underline" }}
                >
                  designers@losode.com
                </a>
              </div>
            )}

            {rejectionConfirm === "alternate colors" && (
              <div
                style={{
                  background: "black",
                  color: "white",
                  padding: "20px",
                  margin: "30px auto 50px",
                  fontSize: "14px",
                }}
              >
                We can manage the buyer’s expectations on your behalf by letting
                them know that their ordered item is available in a different
                colour. Instead of rejecting the order, send a message to our
                designer team at{" "}
                <a
                  href="mailto:designers@losode.com"
                  style={{ color: "white", textDecoration: "underline" }}
                >
                  designers@losode.com
                </a>
              </div>
            )}

            <div
              style={{
                display: "flex",
                gap: "20px",
                justifyContent: "flex-end",
                flexDirection: !isTabletOrMobile ? "row" : "column",
              }}
            >
              <Button
                style={{ height: "47px", padding: "0 50px" }}
                onClick={() => cancelRejectionModal()}
              >
                Cancel
              </Button>
              {rejectionConfirm === "" && (
                <Button
                  style={{
                    background: "black",
                    color: "white",
                    height: "47px",
                    padding: "0 50px",
                  }}
                  onClick={() => setRejectionConfirm(rejectionReason)}
                >
                  Submit
                </Button>
              )}
              {rejectionConfirm !== "" && (
                <Button
                  style={{
                    background: "black",
                    color: "white",
                    height: "47px",
                    padding: "0 50px",
                  }}
                >
                  Continue Reject Order
                </Button>
              )}
            </div>
          </div>
        </Modal>

        {/* <HelpFeedback /> */}

        <style jsx="true">{`
          .manage-listing-all-content {
            position: relative;
          }
          .order-details-body-content-header-container {
            margin-bottom: 24px;
          }
          .order-details-body-content-header {
            display: flex;
            justify-content: space-between;
            padding: 24px 40px 0;
            background: white;
          }
          .order-details-body-content-header-title {
            font-size: 24px;
            font-weight: bold;
          }
          .order-details-body-content-header-subtitle {
            font-size: 14px;
            color: #707070;
          }
          .order-details-container {
            font-family: DomaineSansText-Light;
            background: #f7f7f7;
          }
          .order-details-main-content {
          }
          .order-details-adspace {
            background-image: url("/images/seller-stats-banner.png");
            height: 211px;
            background-repeat: no-repeat;
            width: 80%;
            background-position: bottom;
            margin: auto;
          }
          .section-in-order-details-row {
            padding: 20px 40px;
            background: white;
            display: flex;
            gap: 40px;
            margin-bottom: 40px;
          }
          .section-in-order-details {
            padding: 20px 40px;
            background: white;
            display: flex;
            gap: 16px;
            flex-direction: column;
            margin-bottom: 40px;
          }
          .order-details-section-title {
            font-weight: bold;
            min-width: 150px;
            font-size: 18px;
          }
          .order-info-section table {
            border-collapse: unset;
          }
          .order-info-section td {
            padding: 0 20px 20px 0;
            font-family: "DomaineSansText-Regular";
          }
          .order-info-label {
            color: #707070;
            width: 400px;
          }
          .ant-steps-label-vertical .ant-steps-item-title {
            font-weight: bold;
            font-size: 14px;
          }
          .ant-steps-item-description {
            font-size: 12px;
          }
          .delivery-address-section {
            margin-top: -40px;
          }

          .order-details-payment-table-container {
            padding: 20px;
            background: rgba(0, 0, 0, 0.04);
            margin-top: 40px;
            margin-left: 40px;
          }
          .payment-info-section table {
            border-collapse: unset;
          }
          .payment-info-section td {
            padding: 0 80px 20px 0;
            width: 245px;
          }
          .order-details-payment-image {
            width: 200px;
            height: 200px;
            margin: 20px 0;
            object-fit: contain;
          }
          .order-details-payment-table-divider {
            border-bottom: 0.5px solid black;
            width: 100%;
            margin-bottom: 10px;
          }
          .order-info-number {
            font-family: "DomaineSansText-Black";
          }
          .product-color-section-box {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            display: inline-block;
            vertical-align: sub;
          }
          .order-details-sidenav-container {
            padding: 50px 0;
            position: fixed;
            background: white;
            width: 200px;
            border-right: 1px solid #e4e4e4;
          }
          .action-section {
            justify-content: center;
            gap: 20px;
            padding: 40px;
          }
          .action-section-divider {
            border-right: 1px solid black;
          }
          .action-section-link {
            cursor: pointer;
            text-decoration: underline;
          }
          .action-section-link:hover {
            color: #800000;
          }

          @media (max-width: 640px) {
            .order-details-coverall-container {
              margin-left: 0;
            }
            .order-details-adspace {
              background-image: url("/images/seller-stats-banner.png");
              height: 120px;
              background-repeat: no-repeat;
              width: 100%;
              background-position: center;
              background-color: black;
              margin: auto;
              background-size: cover;
            }
            .hello-store-mobile {
              padding: 0px 16px 16px;
              font-weight: bold;
              font-size: 18px;
            }

            .order-details-body-main {
              padding: 0 16px;
              padding-bottom: 40px;
              margin-right: 0;
            }
            .order-details-body-content-header-container {
              display: flex;
              align-items: center;
            }
            .order-details-body-content-header-title {
              font-size: 18px;
            }
            .order-details-body-sidebar-add-listing-button {
              padding: 0 16px;
              height: 40px;
              font-size: 14px;
              width: 169px;
            }

            .order-details-body-content-listings-container {
              display: block;
              margin-bottom: 40px;
            }
            .order-details-body-content-header-container {
              display: block;
              align-items: center;
              margin-bottom: 16px;
            }
            .order-details-body-content-header {
              padding: 24px 16px;
              flex-direction: column;
              padding-bottom: 0;
            }
            .order-details-body-content-header-subtitle {
              width: 100%;
              font-size: 14px;
            }
            .order-details-body-container {
              flex-direction: column-reverse;
              margin-top: 0;
              justify-content: center;
            }
            .section-in-order-details {
              padding: 16px;
              flex-direction: column;
              gap: 8px;
              margin-bottom: 24px;
            }
            .order-info-section td {
              padding: 0 8px 10px 0;
            }
            .ant-steps-item-title {
              font-size: 14px;
            }
            .order-details-payment-image {
              margin: 16px 0;
              width: 120px;
              height: 120px;
              object-fit: cover;
            }
            .order-details-payment-table-container {
              margin-top: 24px;
              margin-left: 0;
            }
            .no-reason-textbox {
              margin-top: -30px;
              margin-bottom: 30px;
            }
          }
        `}</style>
      </div>
    </Dashboard>
  );
};

export default OrderDetails;
